import React from 'react'
import img1 from '../../../assets/images/features/Vector.png'
import img2 from '../../../assets/images/features/Vector (1).png'
import leftImg from '../../../assets/images/Home Page/common/Rectangle 34625520.png'
import { FaArrowRight } from 'react-icons/fa6'

function AboutUs() {
    return (
        <div className='relative px-[8%] py-5 lg:py-10'>
            <img className='absolute w-40 left-0 top-0 opacity-45' src={img1} alt="icon" />
            <img className='absolute w-28 right-0 top-0 opacity-45' src={img2} alt="icon" />
            <div className='grid grid-cols-12 gap-5'>
                <div className='col-span-12 lg:col-span-6'>
                    <img className='object-contain w-full' src={leftImg} alt="banner-image" />
                </div>
                <div className='col-span-12 lg:col-span-6 lg:mt-8'>
                    <div className='lg:ms-10 font-Roboto'>
                        <h1 className='text-navyverylight font-medium text-3xl'>About Us</h1>
                        <h1 className='text-navydark text-4xl font-medium mt-3'>Crafting Quality</h1>
                        <h1 className='text-navydark text-4xl font-medium mt-1'>Construction, <span className='text-orange-400'>Tailored To You.</span></h1>
                        <div className='mt-5'>
                            <p className='text-gray-500 font-Roboto text-lg mb-4'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure.</p>
                            <p className='text-gray-500 font-Roboto text-lg mb-4'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure.</p>
                            <p className='text-gray-500 font-Roboto text-lg mb-4'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure.</p>
                        </div>
                        <button className='flex items-center border-none bg-yellowBtn rounded-[25px] px-5 py-3 mt-8'>Know More <span className='ms-1'><FaArrowRight /></span></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
