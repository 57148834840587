import { Button, Table, Tooltip } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from 'react-icons/fa';
import { finalUpdate, getRequirement, stepFiveUpdate } from '../../../../../apiServices/api-functions/requirementApi';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import cupIcon from '../../../../../assets/images/Home Page/requirement/SUCCESS CUP.png'
import star from '../../../../../assets/images/Home Page/requirement/star_11884120 3.png'
import { FaArrowRight, FaCircleCheck, FaCircleExclamation, FaCircleXmark } from 'react-icons/fa6';
import { MdWavingHand } from "react-icons/md";
import { webToDashboard } from '../../../../../apiServices/api-functions/authApi';
import { useNavigate } from 'react-router-dom';

function StepFiveEstimation({ complete, step }) {
    const [loan, setLoan] = useState('pending')
    const [plan, setPlan] = useState('pending')
    const reqId = localStorage.getItem('reqId')
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useState('floorDetails');
    const navigate = useNavigate();

    const getRequirementData = useQuery({
        queryKey: [`requirementData`],
        queryFn: () => getRequirement(reqId ?? '')
    })

    const webToDashboardNav = async () => {
        const updateApi = await webToDashboard();
        if (updateApi) {
            const { accessToken, refreshToken } = updateApi?.data?.result?.tokens;
            const roleName = updateApi?.data?.result?.user?.role?.name;

            // Construct the URL with query parameters
            const newUrl = `https://devapp.getstartbuild.com/reconnect/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}`;

            // Open the URL in a new tab
            window.open(newUrl, '_blank');
        }
    };

    const loanTypeValidate = getRequirementData?.data?.data?.result?.loanRequired
    const customerPlanValidate = getRequirementData?.data?.data?.result?.customerPlan

    const getStatusSpan = (status) => {
        switch (status) {
            case 'required':
                return <span className='text-green-500 me-1 cursor-pointer'>
                    <Tooltip content='Loan Required: Yes'>
                        <FaCheckCircle />
                    </Tooltip>
                </span>;
            case 'notRequired':
                return <span className='text-red-500 me-1 cursor-pointer'>
                    <Tooltip content='Loan Required: No'>
                        <FaTimesCircle />
                    </Tooltip>
                </span>;
            case 'pending':
                return <span className='text-yellow-300 me-1 cursor-pointer'>
                    <Tooltip content='Pending: Click Yes / No'>
                        <FaExclamationCircle />
                    </Tooltip>
                </span>;
            default:
                return null;
        }
    };

    const loanSubmit = async (loanType) => {
        setLoan(loanType)
        const payload = {
            loanRequired: loanType,
            customerPlan: plan
        }
        const updateApi = await stepFiveUpdate(reqId, payload)
        if (updateApi) {
            toast.success('Loan Type Updated')
            queryClient.invalidateQueries([
                'stepRequirement',
            ]);
            queryClient.invalidateQueries([
                'requirementData',
            ]);
        }
    }
    const planSubmit = async (planType) => {
        setPlan(planType)
        const payload = {
            loanRequired: loan,
            customerPlan: planType
        }
        const updateApi = await stepFiveUpdate(reqId, payload)
        if (updateApi) {
            toast.success('Updated Successfully')
            queryClient.invalidateQueries([
                'stepRequirement',
            ]);
            queryClient.invalidateQueries([
                'requirementData',
            ]);
        }
    }

    return (
        <>
            {/* {complete ? ( */}
            <div className=''>
                <div className="text-center mt-5">
                    <h1 className="text-green-500 font-medium">Congratulations !</h1>
                    <h3 className='mt-2 text-center'> Hi <span className='font-medium'>{getRequirementData?.data?.data?.result?.visitor?.name}</span> Your {getRequirementData?.data?.data?.result?.constructionType?.name} Plan Requirement Preview</h3>
                </div>
                <div className="mt-3">
                    <div className="flex justify-center">
                        <div className={` px-4 py-2  ${activeTab === 'floorDetails' ? 'border-b-2 border-black text-black font-medium' : ' cursor-pointer'}`} onClick={() => setActiveTab('floorDetails')}>Floor Details</div>
                        <div className={` px-4 py-2  ${activeTab === 'additionalFeatures' ? 'border-b-2 border-black text-black font-medium' : 'cursor-pointer'}`} onClick={() => setActiveTab('additionalFeatures')}>Additional Features</div>
                    </div>
                </div>
                {activeTab === 'floorDetails' && (
                    <div className='mt-3'>
                        {/* <h5 className="text-center">Floor Details</h5> */}
                        <div style={{ maxHeight: '161px', overflowY: 'scroll' }}>
                            <Table>
                                <thead style={{ border: '1px solid gray' }}>
                                    <tr>
                                        <th className='text-center p-2'>
                                            #
                                        </th>
                                        <th className='ps-2' style={{ borderLeft: '1px solid gray' }}>
                                            No Of Floors
                                        </th>
                                        <th className='ps-2' style={{ borderLeft: '1px solid gray' }}>
                                            Area
                                        </th>
                                        <th className='ps-2' style={{ borderLeft: '1px solid gray' }}>
                                            Package
                                        </th>
                                        <th className='ps-2 text-center' style={{ borderLeft: '1px solid gray' }}>
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style={{ border: '1px solid gray' }}>
                                    {getRequirementData?.data?.data?.result?.floorDetails?.map((details, index) => (
                                        <tr key={index} style={{ border: '1px solid gray' }}>
                                            <th scope="row" className='text-center'>
                                                {index + 1}
                                            </th>
                                            <td className='ps-2' style={{ borderLeft: '1px solid gray', }}>
                                                {details?.floor?.name}
                                            </td>
                                            <td className='ps-2' style={{ borderLeft: '1px solid gray', }}>
                                                {details?.SBUA?.squareFeet}
                                            </td>
                                            <td style={{ borderLeft: '1px solid gray', textAlign: 'center' }}>
                                                {(() => {
                                                    switch (details?.packagePlan?.planName) {
                                                        case 'STANDARD':
                                                            return 'STD';
                                                        case 'PREMIUM':
                                                            return 'PRE';
                                                        case 'LUXURY':
                                                            return 'LXRY';
                                                        default:
                                                            return '';
                                                    }
                                                })()}<br />
                                                {details?.packagePlan?.price} / Sqft
                                            </td>
                                            <td className='pe-2' style={{ borderLeft: '1px solid gray', textAlign: 'end' }}>
                                                {details?.amount.toLocaleString('en-IN', {
                                                    style: 'currency',
                                                    currency: 'INR',
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                )}
                {activeTab === 'additionalFeatures' && (
                    <div className='mt-3'>
                        {/* <h5 className="text-center">Additional Features</h5> */}
                        <div style={{ maxHeight: '161px', overflowY: 'scroll' }}>
                            <Table>
                                <thead style={{ border: '1px solid gray' }}>
                                    <tr>
                                        <th className='text-center p-2'>
                                            #
                                        </th>
                                        <th className='ps-2' style={{ borderLeft: '1px solid gray' }}>
                                            Name
                                        </th>
                                        <th className='ps-2' style={{ borderLeft: '1px solid gray' }}>
                                            Area
                                        </th>
                                        <th className='ps-2' style={{ borderLeft: '1px solid gray' }}>
                                            Package
                                        </th>
                                        <th className='ps-2 text-center' style={{ borderLeft: '1px solid gray' }}>
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style={{ border: '1px solid gray' }}>
                                    {getRequirementData?.data?.data?.result?.additionalFeatures?.map((details, index) => (
                                        <tr key={index} style={{ border: '1px solid gray' }}>
                                            <th scope="row" className='text-center'>
                                                {index + 1}
                                            </th>
                                            <td className='ps-2' style={{ borderLeft: '1px solid gray', }}>
                                                {details?.featureName?.name}
                                            </td>
                                            <td className='ps-2' style={{ borderLeft: '1px solid gray', }}>
                                                {details?.SBUA?.squareFeet}
                                            </td>
                                            <td style={{ borderLeft: '1px solid gray', textAlign: 'center' }}>
                                                {(() => {
                                                    switch (details?.packagePlan?.planName) {
                                                        case 'STANDARD':
                                                            return 'STD';
                                                        case 'PREMIUM':
                                                            return 'PRE';
                                                        case 'LUXURY':
                                                            return 'LXRY';
                                                        default:
                                                            return '';
                                                    }
                                                })()}<br />
                                                {details?.planPrice} / Sqft
                                            </td>
                                            <td className='pe-2' style={{ borderLeft: '1px solid gray', textAlign: 'end' }}>
                                                {details?.amount.toLocaleString('en-IN', {
                                                    style: 'currency',
                                                    currency: 'INR',
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                )}
                <div className='grid grid-cols-12 mt-5'>
                    <div className='col-span-5'>
                        <img className='h-[120px] ' src={cupIcon} alt="icon" />
                    </div>
                    <div className='col-span-7'>
                        <h1 className='text-xs text-center font-medium mt-5'>Your Approximate budget of the Requirement</h1>
                        <h1 className='text-4xl mt-3 text-green-500 text-center font-medium'>₹{getRequirementData?.data?.data?.result?.totalBudget}</h1>
                    </div>
                    <div className='col-span-12'>
                        <div className='flex justify-between items-center mt-3'>
                            <div className='flex items-center'>
                                {loanTypeValidate === 'pending' ? (
                                    <span className='text-yellow-500'><FaCircleExclamation size={17} className='me-2' /></span>
                                ) : (
                                    <>
                                        {loanTypeValidate === 'required' ? (
                                            <span className='text-green-500'><FaCircleCheck size={17} className='me-2' /></span>
                                        ) : (
                                            <span className='text-red-500'><FaCircleXmark size={17} className='me-2' /></span>
                                        )}
                                    </>
                                )}
                                <h1 className='text-base text-navyverylight font-small'>
                                    {/* {getStatusSpan(loan)} */}
                                    Do you require a Loan for this Project?</h1>
                            </div>
                            <div className='flex items-center'>
                                <Button onClick={() => {
                                    // setLoan('required')
                                    loanSubmit('required')
                                }} color='dark' size='sm'>Yes</Button>
                                <Button onClick={() => {
                                    // setLoan('notRequired')
                                    loanSubmit('notRequired')
                                }} color='gray' size='sm' className='ms-2'>No</Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12'>
                        <div className='flex justify-between items-center mt-3'>
                            <div className='flex items-center'>
                                {customerPlanValidate === 'pending' ? (
                                    <span className='text-yellow-500'><FaCircleExclamation size={17} className='me-2' /></span>
                                ) : (
                                    <>
                                        {customerPlanValidate === 'yes' ? (
                                            <span className='text-green-500'><FaCircleCheck size={17} className='me-2' /></span>
                                        ) : (
                                            <span className='text-red-500'><FaCircleXmark size={17} className='me-2' /></span>
                                        )}
                                    </>
                                )}
                                <h1 className='text-base text-navyverylight m-0 font-small'>
                                    {/* {getStatusSpan(loan)} */}
                                    Do you have Achitecture Plan for this Project?</h1>
                            </div>
                            <div className='flex items-center'>
                                <Button onClick={() => {
                                    // setPlan('yes')
                                    planSubmit('yes')
                                }} color='dark' size='sm'>Yes</Button>
                                <Button onClick={() => {
                                    // setPlan('no')
                                    planSubmit('no')
                                }} color='gray' size='sm' className='ms-2'>No</Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 flex justify-end w-full mt-5'>

                        <button onClick={webToDashboardNav} className='cursor-pointer p-2 flex items-center bg-navyverylight text-white text-xs  rounded-md'>Countinue to dashboard <span className='ms-1'><FaArrowRight /></span></button>

                    </div>
                    {/* <div>
                        <div className='flex w-[350px] mt-5 text-center'>
                            <h1 className='text-xs flex text-center font-medium mt-2 '>Your requirement was sent to admin kindly wait for  get proposal from multiple builders, proceed to your dashboard.</h1>
                        </div>
                        <div className='flex w-[250px] justify-end mt-2'>
                            <a href='https://devapp.getstartbuild.com/app-customer/sign-up' target='_blank' className='cursor-pointer p-2 bg-navyverylight text-white text-xs mt-2 rounded-md'>Countinue to dashboard</a>
                        </div>
                    </div> */}

                    {/* <img className='h-10 absolute top-0 right-0' src={star} alt="icon" />
                        <img className='h-8 absolute bottom-0 left-0' src={star} alt="icon" /> */}
                </div>
            </div >
            {/* ) : ( */}
            {/* <div className='flex flex-col justify-between h-full'>
                <div>
                    <div>
                        <h1 className='font-medium text-xl text-gray-800 font-Roboto'>Get Estimation</h1>
                        <p className='text-xs text-gray-400 mt-1 mb-5 font-Roboto'>Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's.</p>
                    </div>
                    <div className='flex flex-col justify-center items-center mt-5'>
                        <h1 className='text-base text-navyverylight font-medium flex items-center'>{getStatusSpan(loan)}Do you require a Loan for this Project?</h1>
                        <div className='flex items-center mt-2'>
                            <Button onClick={() => loanSubmit('required')} color='dark' size='sm'>Yes</Button>
                            <Button onClick={() => loanSubmit('notRequired')} color='gray' size='sm' className='ms-2'>No</Button>
                        </div>
                    </div>
                </div>
                {step && (
                    <div className='flex justify-end'>
                        <div>
                            <button onClick={finalApproval} className='next-btn' >Get Estimation <span className='ms-1 pt-[3px]'><FaCheckCircle /></span></button>
                        </div>
                    </div>
                )}
            </div> */}
            {/* )} */}
        </>

    )
}

export default StepFiveEstimation
