import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight, FaCircleXmark } from 'react-icons/fa6'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { additionalFeatureUpdateApi, getAdditional, getRequirement } from '../../../../../apiServices/api-functions/requirementApi'
import toast from 'react-hot-toast';
import { Spinner } from 'flowbite-react';

function StepFourEstimation({ nextPage, prevPage }) {
    const [selectedItems, setSelectedItems] = useState([]);
    const reqId = localStorage.getItem('reqId')
    const queryClient = useQueryClient();

    const handleItemClick = (itemId) => {
        // Check if the item is already selected
        const isSelected = selectedItems.some(item => item.id === itemId);

        if (isSelected) {
            // If selected, remove it from the selected items
            const updatedItems = selectedItems.filter(item => item.id !== itemId);
            setSelectedItems(updatedItems);
        } else {
            // If not selected, add it to the selected items
            const selectedItem = getAdditionalData?.data?.data?.result.find(item => item._id === itemId);
            if (selectedItem) {
                setSelectedItems(prevItems => [...prevItems, { id: selectedItem._id, name: selectedItem.name }]);
            }
        }
    };

    const removeItemClick = (itemId) => {
        const updatedItems = selectedItems.filter(item => item.id !== itemId);
        setSelectedItems(updatedItems);
    }

    console.log(selectedItems);

    const getAdditionalData = useQuery({
        queryKey: ['getAdditonalFea'],
        queryFn: () => getAdditional()
    })
    const getRequirementData = useQuery({
        queryKey: [`requirementData`],
        queryFn: () => getRequirement(reqId ?? '')
    })

    const getData = getRequirementData?.data?.data?.result?.additionalFeatures
    useEffect(() => {
        if (getData) {
            const features = getData?.map(item => ({
                id: item.featureName._id,
                name: item.featureName.name
            }));
            setSelectedItems(features);
        }
    }, [getData])
    const proceedFunc = async () => {
        // Extracting IDs from selectedItems
        const additionalFeatures = selectedItems.map(item => ({ featureName: item.id }));
        const payload = {
            additionalFeatures: additionalFeatures
        };

        console.log(payload);
        const updateApi = await additionalFeatureUpdateApi(reqId, payload)
        if (updateApi) {
            toast.success('Additional Feature Updated')
            localStorage.setItem('reqId', updateApi?.data?.result?._id)
            queryClient.invalidateQueries([
                'stepRequirement',
            ]);
            nextPage()
        }

    }

    const { isLoading, isError } = getAdditionalData;
    if (isLoading) {
        return (
            <div className="h-full flex items-center justify-center">
                <Spinner color="info" aria-label="Info spinner example" />
            </div>
        );
    }

    if (isError) {
        return <div>Error: Unable to fetch data</div>;
    }

    return (
        <div className='flex flex-col justify-between h-full'>
            <div>
                <div>
                    <h1 className='font-medium text-xl text-gray-800 font-Roboto'>Additional Features</h1>
                    <p className='text-xs text-gray-400 mt-1 mb-5 font-Roboto'>Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's.</p>
                </div>
                {selectedItems.length >= 1 && (
                    <>
                        <div className='flex flex-wrap gap-2 mt-3'>
                            {selectedItems.map((item, i) => (
                                <div key={i} className='bg-navyverylight flex justify-center items-center p-1 rounded-md'>
                                    <h1 className='text-white text-xs flex items-center'>{item?.name}<span onClick={() => removeItemClick(item?.id)} className='ms-1 cursor-pointer'><FaCircleXmark /></span></h1>
                                </div>
                            ))}
                        </div>
                        <div className='w-full h-[2px] bg-navydark mt-2'></div>
                    </>
                )}
                <div className='flex flex-wrap gap-4 mt-3'>
                    {getAdditionalData?.data?.data?.result?.map((item, i) => (
                        <div
                            key={i}
                            onClick={() => handleItemClick(item._id)}
                            className={`flex flex-col items-center justify-center cursor-pointer`}
                        >
                            <div className={`h-12 w-12 flex justify-center items-center rounded-full border-gray-600 border-[1px] ${selectedItems.some(selectedItem => selectedItem.id === item._id) ? 'border-[2px] border-gray-600 ' : ''}`}>
                                <img className='h-7' src={item?.icon} alt="icon" />
                            </div>
                            <h1 className='mt-1 text-xs'>{item?.name}</h1>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex">
                <div className='flex ml-auto'>
                    <div>
                        <button className='prev-btn' onClick={prevPage} > <FaArrowLeft className="cursor-pointer me-1" /> Previous</button>
                    </div>
                </div>
                <div className='flex'>
                    <div>
                        <button className='next-btn' onClick={proceedFunc}>Proceed to Next <span className='ms-1 pt-[3px]'><FaArrowRight /></span></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepFourEstimation
