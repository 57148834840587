import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import {
    Controller,
    SubmitHandler,
    useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import FloorRoomInput from './FloorRoomInput';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { floorAddRemove, floorRoom, floorUpdateApi, getPlanType, getRequirement } from '../../../../../apiServices/api-functions/requirementApi';
import { Tooltip } from 'flowbite-react';
import { FaCheckSquare } from 'react-icons/fa';
import toast from 'react-hot-toast';

function FloorUpdate({ floor, dataTab, length, addTab, removeTab, onNextTab }) {

    const [sliderValue, setSliderValue] = useState([600, 5000]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const reqId = localStorage.getItem('reqId')
    const queryClient = useQueryClient();

    const BasicFormSchema = yup.object().shape({

    });

    const { control, handleSubmit, setValue, formState: { errors }, register } = useForm({
        resolver: yupResolver(BasicFormSchema),
        defaultValues: {
        }
    });

    // const onSubmit = async (data) => {
    //     console.log(data);
    //     // nextPage()
    // };

    // const handleProceedToNext = () => {
    //     handleSubmit(onSubmit)();
    // };
    const handlePackageSelect = (item) => {
        if (selectedPackage === item) {
            setSelectedPackage(null); // Unselect the package if it's already selected
        } else {
            setSelectedPackage(item); // Otherwise, select the package
        }
    };


    const handleSliderChange = (event) => {
        const newValue = event.target.value.split(',').map(Number); // Convert string value to array of numbers
        setSliderValue(newValue); // Update state with new value// Update form value
    };
    const getProgressBarStyleTotal = () => {
        const progressPercentage = ((sliderValue[0] - 600) / (5000 - 600)) * 100;
        return {
            width: `${progressPercentage}%`
        };
    };

    const getRoomData = useQuery({
        queryKey: ['getRoomData'],
        queryFn: () => floorRoom('')
    })
    const getRequirementData = useQuery({
        queryKey: [`requirementData`],
        queryFn: () => getRequirement(reqId ?? '')
    })

    const getPlanData = useQuery({
        queryKey: ['getPackage'],
        queryFn: () => getPlanType()
    })
    console.log(getPlanData?.data?.data?.result);

    const onSubmitFunc = async (data) => {
        delete data.totalLandArea
        const rooms = Object.keys(data).map((roomId) => ({
            name: roomId,
            count: data[roomId]
        }));
        const payload = {
            dimensionType: 'squareFeet',
            SBUA: {
                squareFeet: sliderValue[0]
            },
            floor,
            rooms: rooms,
            packagePlan: selectedPackage,
        }
        const updateApi = await floorUpdateApi(reqId, payload)
        if (updateApi) {
            toast.success(`${dataTab?.name} Updated Successfully`)
            queryClient.invalidateQueries([
                'getFloorTab',
            ]);
            onNextTab();
        }
    }

    const addFloorFunc = async () => {
        const payload = {
            floorCount: 'add'
        }
        const updateApi = await floorAddRemove(reqId, payload)
        if (updateApi) {
            addTab()
        }
        queryClient.invalidateQueries([
            'getFloorTab',
        ]);
    }
    const removeFloorFunc = async () => {
        const payload = {
            floorCount: 'remove'
        }
        const updateApi = await floorAddRemove(reqId, payload)
        if (updateApi) {
            removeTab()
        }
        queryClient.invalidateQueries([
            'getFloorTab',
        ]);
    }


    useEffect(() => {
        if (getRequirementData?.data?.data?.result?.floorDetails && getRequirementData?.data?.data?.result?.floorDetails[floor]) {
            const floorData = getRequirementData?.data?.data?.result?.floorDetails[floor]
            const squareFeetValue = floorData?.SBUA?.squareFeet ?? 600; // default to 600 if null
            setSliderValue([squareFeetValue, 5000]);
            setSelectedPackage(floorData?.packagePlan?._id)
            floorData.rooms.forEach(room => {
                setValue(room.name._id, room.count); // Set the value for each room using setValue
            });
        }
    }, [getRequirementData?.data?.data?.result?.floorDetails])
    return (
        <div>
            <div className='mt-5 grid grid-cols-12 gap-3'>
                <div className='col-span-7'>
                    <div className="mb-1">
                        <p className='text-xs text-gray-800 font-medium font-Roboto'>Carpet Area <span className='text-xs font-medium text-gray-400 ms-1'>({sliderValue[0]} Sqft)</span></p>
                    </div>
                    <div className="range-slider-container mt-3">
                        <input
                            id="sm-range"
                            type="range"
                            className="range-slider"
                            style={{ width: '100%' }}
                            onChange={handleSliderChange}
                            value={sliderValue.join(',')}
                            min={600}
                            max={5000}
                        />
                        <div className="progress-bar" style={getProgressBarStyleTotal()}></div>
                    </div>
                </div>

                <div className="col-span-5 ">
                    <div className="mb-1">
                        <p className='text-xs text-gray-800 font-medium font-Roboto'>Carpet Area <span className='text-xs font-medium text-gray-400 ms-1'>({sliderValue[0]} Sqft)</span></p>
                    </div>
                    <div className='flex'>
                        <div>
                            <input
                                id="sqft"
                                type="text"
                                className="sqft-inp rounded-l-lg py-2"
                                style={{ width: '100%' }}
                                value={sliderValue[0]}
                                onChange={handleSliderChange}
                                min={600}
                                max={5000}

                            />
                            <div className="progress-bar" style={getProgressBarStyleTotal()}></div>
                        </div>
                        <div className='flex items-center justify-center bg-navyverylight px-3 text-white rounded-r-lg'>
                            <span>Sqft</span>
                        </div>
                    </div>

                </div>



            </div>

            <div className='mt-5'>
                <div className="grid grid-cols-12 gap-4 gap-y-4">
                    {getRoomData?.data?.data?.result?.map((item, i) => (
                        <div key={i} className='col-span-6'>
                            <Controller
                                name={`${item?._id}`}
                                control={control}
                                defaultValue={1}
                                render={({ field: { onChange, value } }) => (
                                    <FloorRoomInput value={value} onChange={onChange} data={item} />
                                )}
                            />
                        </div>
                    ))}

                </div>
            </div>
            <div className='mt-7'>
                <h1 className='text-xs text-gray-800 font-medium font-Roboto'>Choose Package Type</h1>
                <div className='flex flex-wrap justify-between items-center gap-4 mt-4'>
                    {getPlanData?.data?.data?.result?.map((item, i) => (
                        <div key={i}>
                            <div className='flex items-center'>
                                <Tooltip content={`${item?.price}/Sqft`}>
                                    <div className='flex items-center'>
                                        <img className='h-8 me-1' src={item?.icon} alt="icon" />
                                        <div>
                                            <h1 className='ms-1 text-sm font-medium'>{item?.planName}</h1>
                                            <h1 className='ms-1 text-[12px] text-gray-400'>{item?.price} Sqft</h1>
                                        </div>
                                    </div>
                                </Tooltip>
                                <input
                                    className='ms-3 cursor-pointer'
                                    type='checkbox'
                                    checked={selectedPackage === item?._id} // Check if the current package is selected
                                    onChange={() => handlePackageSelect(item?._id)} // Call handler on change
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-end mt-7'>
                <div className='flex items-center'>
                    {/* {length === floor + 1 && dataTab?.completed && (
                        <button onClick={addFloorFunc} className='p-1 me-1 flex items-center justify-center bg-navydark hover:bg-navyverylight rounded-md'>
                            <Tooltip content='Add Floor'>
                                <FaPlus className='text-white' />
                            </Tooltip>
                        </button>
                    )}
                    {length === floor + 1 && floor !== 0 && (
                        <button onClick={removeFloorFunc} className='p-1 me-1 flex items-center justify-center bg-navydark hover:bg-navyverylight rounded-md'>
                            <Tooltip content='Remove Floor'>
                                <FaMinus className='text-white' />
                            </Tooltip>
                        </button>
                    )} */}
                    <button onClick={handleSubmit(onSubmitFunc)} className='bg-navydark hover:bg-navyverylight py-1 px-2 rounded-md text-white text-sm flex items-center me-3' ><FaCheckSquare className='me-1' />Submit</button>

                </div>
            </div>
        </div>
    )
}

export default FloorUpdate
