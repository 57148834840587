let backendDomain = 'https://devapi.getstartbuild.com'

backendDomain = backendDomain
    ? backendDomain.endsWith('/')
        ? backendDomain
        : `${backendDomain}/`
    : '';

const configJson = {
    backendDomain,
};

export default configJson;