import React from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa6';

function FloorRoomInput({ value, onChange, data }) {

    const handleChange = (e) => {
        const inputValue = e.target.value;
        // Use regex to test if the input contains only numeric characters
        if (/^\d*$/.test(inputValue)) {
            onChange(Number(inputValue)); // Update the value if it's a valid number
        }
        // If the input contains non-numeric characters, don't update the value
    };


    const increment = () => {
        onChange(value + 1);
    };

    const decrement = () => {
        if (value > 0) {
            onChange(value - 1);
        }
    };

    return (
        <div className="flex items-center col-span-6">
            <img
                className="h-7 me-2"
                src={data?.icon}
                alt="icons"
            />
            <div>
                <h1 className="text-slate-400 text-xs mb-2">{data?.name}</h1>
                <div className="flex items-center">
                    <div onClick={decrement} className='h-5 w-5 me-2 flex justify-center items-center bg-gray-200'>
                        <FaMinus />
                    </div>
                    <input
                        type="text"
                        className="w-11 h-5 text-center border-none number-input"
                        placeholder="Item quantity"
                        value={value}
                        onChange={handleChange}
                    />
                    <div onClick={increment} className='h-5 w-5 ms-2 flex justify-center items-center bg-gray-200'>
                        <FaPlus />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default FloorRoomInput
