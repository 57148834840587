import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '../App'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import RequirementPage from '../pages/Requirement/RequirementPage'
import ScrollToTop from '../components/scrollToTop'
import PasswordSetMain from '../pages/PasswordSet/PasswordSetMain'
import VerifyEmail from '../pages/PasswordSet/VerifyEmail'

const AppRoutes = () => {
  const [path, setPath] = useState('')

  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  return (
    <BrowserRouter>
      {path !== '/passwordSet/:id' && path !== '/verifyEmail' && (
        <>
          <Navbar />
        </>
      )}
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<App />} />
        {/* <Route path='/services' element={<App />} />
        <Route path='/packages' element={<App />} />
        <Route path='/calculator' element={<App />} />
        <Route path='/contact' element={<App />} />
        <Route path='/requirement' element={<RequirementPage />} /> */}
        <Route path='/passwordSet/:id' element={<PasswordSetMain />} />
        <Route path='/verifyEmail' element={<VerifyEmail />} />
      </Routes>
      {path !== '/passwordSet/:id' && path !== '/verifyEmail' && (
        <>
          <Footer />
        </>
      )}
    </BrowserRouter>

  )
}

export default AppRoutes