import React, { useEffect, useState } from 'react'
import { Label, Radio, Spinner, TextInput, Tooltip } from 'flowbite-react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getContructionType, getRequirement, getRequirementFloor, requirementGET, stepTwoUpdate } from '../../../../../apiServices/api-functions/requirementApi';
import { cutText } from '../../../../../utils/helper';
import toast from 'react-hot-toast';
import Select from 'react-select';

function StepTwoEstimation({ nextPage, prevPage }) {
    const [sliderValue, setSliderValue] = useState([600, 5000]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [dimensionType, setDimensionType] = useState('squareFeet');
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const totalArea = length * width
    const [selectedFacing, setSelectedFacing] = useState(null);
    const queryClient = useQueryClient();
    const reqId = localStorage.getItem('reqId')
    const [selectedFloor, setSelectedFloor] = useState(localStorage.getItem('selectedFloor') || "");

    const handleFacingChange = (facing) => {
        setSelectedFacing(facing);
    };

    const getRequirementData = useQuery({
        queryKey: [`requirementData`],
        queryFn: () => getRequirement(reqId ?? '')
    })

    const requirementFloorData = useQuery({
        queryKey: [`requirementFloorData`],
        queryFn: () => getRequirementFloor()
    })

    const getContructionTypeData = useQuery({
        queryKey: ['getContructionType'],
        queryFn: () => getContructionType()
    })


    const handleSliderChange = (event) => {
        const newValue = event.target.value.split(',').map(Number);
        setSliderValue(newValue);
    };

    const getProgressBarStyleTotal = () => {
        const progressPercentage = ((sliderValue[0] - 600) / (5000 - 600)) * 100;
        return {
            width: `${progressPercentage}%`
        };
    };
    const options = requirementFloorData?.data?.data?.result?.map((item) => (
        {
            label: item?.floors,
            value: item?._id,
        }

    ))
    const handleFloorChange = (selectedOption) => {
        setSelectedFloor(selectedOption);
    };
    const handleProceedToNext = async (e) => {
        const payload = {
            constructionType: selectedItemId,
            directionFacing: selectedFacing,
            dimensionType,
            SBUA: dimensionType === 'squareFeet' ? { squareFeet: sliderValue[0] } : { length: parseInt(length), width: parseInt(width) },
            noOfFloors: selectedFloor.value
        };
        const updateApi = await stepTwoUpdate(payload)
        if (updateApi) {
            localStorage.setItem('reqId', updateApi?.data?.result?._id)
            queryClient.invalidateQueries([
                'stepRequirement',
            ]);
            console.log(updateApi);
            toast.success('Construction Details Updated')
            nextPage()
        }
    };

    useEffect(() => {
        if (getRequirementData?.data?.data?.result?.constructionType) {
            setSelectedItemId(getRequirementData?.data?.data?.result?.constructionType?._id)
            setDimensionType(getRequirementData?.data?.data?.result?.dimensionType)
            setLength(getRequirementData?.data?.data?.result?.SBUA?.length)
            setWidth(getRequirementData?.data?.data?.result?.SBUA?.width)
            setSelectedFacing(getRequirementData?.data?.data?.result?.directionFacing)
            setSelectedFloor(getRequirementData?.data?.data?.result?.noOfFloors?.floors);
            const squareFeetValue = getRequirementData?.data?.data?.result?.SBUA?.squareFeet ?? 600;
            setSelectedFloor({ label: getRequirementData?.data?.data?.result?.noOfFloors?.floors, value: getRequirementData?.data?.data?.result?.noOfFloors?._id })
            setSliderValue([squareFeetValue, 5000]);
        }
    }, [getRequirementData?.data?.data?.result?.constructionType])

    const { isLoading, isError } = getContructionTypeData;
    if (isLoading) {
        return (
            <div className="h-full flex items-center justify-center">
                <Spinner color="info" aria-label="Info spinner example" />
            </div>
        );
    }

    if (isError) {
        return <div>Error: Unable to fetch data</div>;
    }

    return (
        <div className='flex flex-col justify-between h-full'>
            <div>
                <div>
                    <h1 className='font-medium text-xl text-gray-800 font-Roboto'>Construction Details</h1>
                    <p className='text-xs text-gray-400 mt-1 font-Roboto'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                </div>
                <div className='mt-4'>
                    <h1 className='text-xs text-gray-800 font-medium font-Roboto'>Choose Construction Type</h1>
                    <div className='mt-2 flex flex-wrap gap-2'>
                        {getContructionTypeData?.data?.data?.result?.map((item, i) => (
                            <div
                                key={i}
                                className={`cursor-pointer w-[100px] p-2 flex flex-col justify-center items-center rounded-md border-gray-400 border-[2px] ${selectedItemId === item?._id ? 'border-[2px] border-navydark shadow-lg' : ''}`}
                                onClick={() => setSelectedItemId(item?._id)}
                            >
                                <img className='h-6' src={item?.icon} alt="icon" />
                                <Tooltip content={item?.name}>
                                    <p className={`font-Roboto text-xs mt-1 ${selectedItemId === item?._id ? 'text-gray' : ''}`}>{cutText(item?.name, 10)}</p>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='mt-5 flex items-center justify-between'>
                    <h1 className='text-xs text-gray-800 font-medium font-Roboto'>Dimension Type</h1>
                    <div className='flex items-center'>
                        <div className="flex items-center gap-2  me-5">
                            <Radio
                                id="united-state"
                                name="dimension"
                                value="squareFeet"
                                checked={dimensionType === "squareFeet"}
                                onChange={(e) => setDimensionType(e.target.value)}
                            />
                            <Label htmlFor="united-state">Sqft</Label>
                        </div>
                        <div className="flex items-center gap-2">
                            <Radio
                                id="germany"
                                name="dimension"
                                value="dimension"
                                checked={dimensionType === "dimension"}
                                onChange={(e) => setDimensionType(e.target.value)}
                            />
                            <Label htmlFor="germany">Dimension</Label>
                        </div>
                    </div>
                </div>
                {dimensionType === 'squareFeet' ? (
                    <div className='mt-5'>
                        <div className='grid grid-cols-12 gap-4'>
                            <div className='col-span-7'>
                                <div className="mb-1">
                                    <p className='text-xs text-gray-800 font-medium font-Roboto'>Carpet Area <span className='text-xs font-medium text-gray-400 ms-1'>({sliderValue[0]} Sqft)</span></p>
                                </div>
                                <div className="range-slider-container mt-3">
                                    <input
                                        id="sm-range"
                                        type="range"
                                        className="range-slider"
                                        style={{ width: '100%' }}
                                        onChange={handleSliderChange}
                                        value={sliderValue}
                                        min={600}
                                        max={5000}
                                    />
                                    <div className="progress-bar" style={getProgressBarStyleTotal()}></div>
                                </div>
                            </div>
                            <div className="col-span-5 ">
                                <div className="mb-1">
                                    <p className='text-xs text-gray-800 font-medium font-Roboto'>Carpet Area<span className='text-xs font-medium text-gray-400 ms-1'>({sliderValue[0]} Sqft)</span></p>
                                </div>
                                <div className='flex'>
                                    <div>
                                        <input
                                            id="sqft"
                                            type="text"
                                            className="sqft-inp rounded-l-lg"
                                            style={{ width: '100%' }}
                                            value={sliderValue[0]}
                                            onChange={handleSliderChange}
                                            min={600}
                                            max={5000}

                                        />
                                        <div className="progress-bar" style={getProgressBarStyleTotal()}></div>
                                    </div>
                                    <div className='flex items-center justify-center bg-navyverylight px-3 text-white rounded-r-lg'>
                                        <span>Sqft</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='grid grid-cols-12 gap-4'>
                            <div className='col-span-5'>
                                <div className="mb-1">
                                    <p className='text-xs text-gray-800 font-medium font-Roboto'>No of Floors</p>
                                </div>
                                <Select
                                    placeholder='Select'
                                    value={selectedFloor}
                                    onChange={handleFloorChange}
                                    options={options}
                                    defaultValue={selectedFloor}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='mt-5'>
                        <div className="mb-1">
                            <p className='text-xs text-gray-800 font-medium font-Roboto'>Build Up Area <span className='text-xs font-medium text-gray-400 ms-1'>({totalArea || '0'} Sqft)</span></p>
                        </div>
                        <div className='grid grid-cols-12 gap-3'>
                            <div className='col-span-4'>
                                <div className="flex items-center">
                                    <div className='flex items-center justify-center bg-navyverylight h-10 w-14 text-white'>
                                        <span>L</span>
                                    </div>
                                    <TextInput
                                        style={{ borderRadius: '0px' }}
                                        id="length"
                                        type="number"
                                        placeholder="Length"
                                        value={length}
                                        onChange={(e) => setLength(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <div className="flex items-center">
                                    <div className='flex items-center justify-center bg-navyverylight h-10 w-14 text-white'>
                                        <span>W</span>
                                    </div>
                                    <TextInput
                                        style={{ borderRadius: '0px' }}
                                        id="width"
                                        type="number"
                                        placeholder="Width"
                                        value={width}
                                        onChange={(e) => setWidth(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-span-4'>
                                <div className='flex items-center mt-1'>
                                    <h1 className='text-base font-medium text-navydark me-2'>=</h1>
                                    <h1 className='text-lg font-Roboto font-medium text-gray-600 pt-1'>{totalArea || '0'}/Sqft</h1>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-12'>
                            <div className="col-span-4">
                                <div className="mt-2 mb-1">
                                    <p className='text-xs text-gray-800 font-medium font-Roboto'>No of Floors</p>
                                </div>
                                <Select
                                    placeholder='Select'
                                    value={selectedFloor}
                                    onChange={handleFloorChange}
                                    options={options}
                                    defaultValue={selectedFloor}
                                />
                            </div>
                        </div>
                    </div>
                )}


                <div className='mt-3'>
                    <h1 className='text-xs text-gray-800 font-medium font-Roboto'>Facing</h1>
                    <div className="grid grid-cols-12 gap-5">
                        <div className='col-span-6 mt-2'>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-blvvhh3ucltsdazo1.png' alt="icon" />
                                <p className='text-xs font-Roboto mx-1'>North</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('north')}
                                        checked={selectedFacing === 'north'}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-blvvhh3ucltsdad9v.png' alt="" />
                                <p className='text-xs font-Roboto mx-1'>East</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('east')}
                                        checked={selectedFacing === 'east'}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-blvvhh3ucltsd9rzn.png' alt="" />
                                <p className='text-xs font-Roboto mx-1'>West</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('west')}
                                        checked={selectedFacing === 'west'}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-blvvhh3ucltsd94ns.png' alt="icon" />
                                <p className='text-xs font-Roboto mx-1'>South</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('south')}
                                        checked={selectedFacing === 'south'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-6 mt-2'>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.amazonaws.com/deep/deeblue/get-start-bulid/file-17akn5bxjslucwlvsf.png' alt="icon" />
                                <p className='text-xs font-Roboto mx-1'>South West</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('southWest')}
                                        checked={selectedFacing === 'southWest'}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-17akn5bxjslucwrtti.png' alt="icon" />
                                <p className='text-xs font-Roboto mx-1'>South East</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('southEast')}
                                        checked={selectedFacing === 'southEast'}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-17akn5bxjslucwphsc.png' alt="icon" />
                                <p className='text-xs font-Roboto mx-1'>North West</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('northWest')}
                                        checked={selectedFacing === 'northWest'}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <img className='h-8' src='https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-17akn5bxjslucwqwcr.png' alt="icon" />
                                <p className='text-xs font-Roboto mx-1'>North East</p>
                                <div className="ml-auto">
                                    <input
                                        type='checkbox'
                                        onChange={() => handleFacingChange('northEast')}
                                        checked={selectedFacing === 'northEast'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="flex">
                <div className='flex ml-auto'>
                    <div>
                        <button className='prev-btn' onClick={prevPage} > <FaArrowLeft className="cursor-pointer me-1" /> Previous</button>
                    </div>
                </div>
                <div className='flex'>
                    <div>
                        <button className='next-btn' onClick={handleProceedToNext}>Proceed to Next <span className='ms-1 pt-[3px]'><FaArrowRight /></span></button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default StepTwoEstimation
