import { Button, Modal } from 'flowbite-react'
import React from 'react'
import EstimationIndex from './Requirement/EstimationIndex'

function RequirementModal({ isModal, toggle, count }) {
    return (
        <Modal size="4xl" show={isModal} className='modal-custom-height'>
            <div className='p-3 modal-content'>
                <EstimationIndex count={count} toggle={toggle} />
            </div>
        </Modal>
    )
}

export default RequirementModal
