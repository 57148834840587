import { apiFunctions } from "../ApiService";
import siteUrls from "../SiteURLs";


export const mobileOTPSignup = async (payload) => {
    const mobileSignup = await apiFunctions.post(
        siteUrls.auth.mobileSignup,
        payload
    );
    return mobileSignup;
};

export const mobileOTPSignUpVerify = async (payload) => {
    const mobileSignup = await apiFunctions.post(
        siteUrls.auth.mobileSignupVerify,
        payload
    );
    return mobileSignup;
};
export const webToDashboard = async (payload) => {
    const webToDashboardApi = await apiFunctions.post(
        siteUrls.requirement.form.stepFive.nav,
        {}
    );
    return webToDashboardApi;
};