import React from 'react'
import bgimage from "../../../assets/images/Home Page/ConstructionPackages/Vec.png"
import leftImg from '../../../assets/images/Home Page/common/Rectangle 34625524.png'
import { FaArrowRight } from 'react-icons/fa6'

function ServiceSection() {
    const mockData = [
        {
            step: 'Step 01',
            title: 'Residential Construction',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised.'
        },
        {
            step: 'Step 02',
            title: 'Commercial Construction',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised.'
        },
        {
            step: 'Step 03',
            title: 'Renovations and Remodeling',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised.'
        },
        {
            step: 'Step 04',
            title: 'Project Management',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised.'
        },
    ]
    return (
        <div className='relative px-[8%] py-5 lg:py-10'>
            <img src={bgimage} className='absolute top-0 left-0 -z-[50] w-[30%] md:w-[20%] opacity-45' />
            <div className='grid grid-cols-12 gap-5'>
                <div className='col-span-12 lg:col-span-6 lg:mt-5'>
                    <div className='lg:me-10 font-Roboto'>
                        <h1 className='text-navyverylight font-medium text-3xl'>Services</h1>
                        <h1 className='text-navydark text-4xl font-medium mt-3'>Crafting Quality</h1>
                        <h1 className='text-navydark text-4xl font-medium mt-1'>Construction, <span className='text-orange-400'>Tailored To You.</span></h1>
                        <div className='mt-5 lg:mt-10'>
                            {mockData?.map((item, i) => (
                                <div key={i} className='mb-8 font-Roboto'>
                                    <p className='text-lg font-medium text-navyverylight'>{item?.step}</p>
                                    <h1 className='text-xl font-medium text-navydark mt-1 mb-3'>{item?.title}</h1>
                                    <p className='text-gray-500 mb-3'>{item?.description}</p>
                                    <hr />
                                </div>
                            ))}
                        </div>
                        <button className='flex items-center border-none bg-yellowBtn rounded-[25px] px-5 py-3'>Know More <span className='ms-1'><FaArrowRight /></span></button>
                    </div>
                </div>
                <div className='col-span-12 lg:col-span-6'>
                    <img className='object-contain w-full' src={leftImg} alt="banner-image" />
                </div>
            </div>
        </div>
    )
}

export default ServiceSection
