import React, { useEffect, useState } from 'react'
import './EstimationIndex.css'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon from '../../../../assets/images/Home Page/requirement/Group 1597881653.png'
import StepOneEstimation from './steps/StepOneEstimation';
import StepTwoEstimation from './steps/StepTwoEstimation';
import StepThreeEstimation from './steps/StepThreeEstimation';
import StepFourEstimation from './steps/StepFourEstimation';
import StepFiveEstimation from './steps/StepFiveEstimation';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { requirementStep, stepOneGET } from '../../../../apiServices/api-functions/requirementApi';
import { Spinner } from 'flowbite-react';
import { FaLock } from 'react-icons/fa6';
import topRight from '../../../../assets/images/Home Page/requirement/topp.png'
import cardTopLeft from '../../../../assets/images/Home Page/requirement/CARD LEFT TOP.png'
import cardBottomRight from '../../../../assets/images/Home Page/requirement/card bottom right.png'

function EstimationIndex({ count, toggle }) {
    const [currentPage, setCurrentPage] = useState(1);
    const queryClient = useQueryClient()

    // useEffect(() => {
    //     setCurrentPage(parseInt(count) + 1)
    // }, [])

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const roleName = localStorage.getItem('userId')

    const getStepData = useQuery({
        queryKey: ['stepRequirement'],
        queryFn: () => requirementStep(roleName ?? '')
    })

    const { isLoading, isError } = getStepData;
    if (isLoading) {
        return (
            <div className="h-[550px] flex items-center justify-center">
                <Spinner color="info" aria-label="Info spinner example" />
            </div>
        );
    }

    if (isError) {
        return <div>Error: Unable to fetch data</div>;
    }




    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        centerMode: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 908,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],

    };

    const renderPageContent = () => {
        switch (currentPage) {
            case 1:
                return <StepOneEstimation nextPage={handleNextPage} toggle={toggle} />;
            case 2:
                return (
                    <StepTwoEstimation
                        nextPage={handleNextPage}
                        prevPage={handlePrevPage}
                    />
                );
            case 3:
                return (
                    <StepThreeEstimation
                        nextPage={handleNextPage}
                        prevPage={handlePrevPage}
                    />
                );
            case 4:
                return (
                    <StepFourEstimation
                        nextPage={handleNextPage}
                        prevPage={handlePrevPage}
                    />
                );
            case 5:
                return <StepFiveEstimation prevPage={handlePrevPage} complete={getStepData?.data?.data?.result?.formComplete} step={getStepData?.data?.data?.result?.details[4]?.status} />;
            default:
                return null;
        }
    };


    return (
        <div style={{ width: '100%' }}>
            <div className='grid grid-cols-12 gap-4 gap-y-4'>
                <div className='col-span-5'>
                    <div className='bg-[#164E63] h-full p-3 flex flex-col justify-between rounded-lg relative'>
                        <div className='mt-8 mb-5'>
                            {getStepData?.data?.data?.result?.details?.map((item, i) => (
                                <div key={i}>
                                    <div className='flex items-center'>
                                        <button disabled={!item?.status && currentPage !== i + 1} onClick={() => setCurrentPage(i + 1)} className={`step-btn-eastimation border-2 border-black ${item?.status ? 'bg-yellow-300' : 'bg-white'} ${currentPage === i + 1 ? 'bg-yellow-300 shadow-xl border-4 border-white' : ''}`} >
                                            {currentPage === i + 1 ? (
                                                <>{i + 1}</>
                                            ) : (
                                                <>
                                                    {item?.status ? (
                                                        <>{i + 1}</>
                                                    ) : (
                                                        <h1 className='text-[10px]'>
                                                            <FaLock />
                                                        </h1>
                                                    )}
                                                </>
                                            )}
                                        </button>
                                        <h1 className='text-xs font-normal font-Roboto ms-1 text-gray-50'>{item?.title}</h1>
                                    </div>
                                    {getStepData?.data?.data?.result?.details?.length !== i + 1 && (
                                        <div className={`w-[2px] h-8 ms-[10px] bg-gray-50 ${item?.status ? 'bg-yellow-200' : ''}`}></div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <img className='h-32 absolute top-0 right-0' src={topRight} alt="icon" />
                        <div className='bg-[#123E4F] rounded-lg p-3 py-8 w-full relative mt-20'>
                            <img className='h-16 absolute top-0 left-0' src={cardTopLeft} alt="icon" />
                            <img className='h-16 absolute bottom-0 right-0' src={cardBottomRight} alt="icon" />
                            <Slider {...settings}>
                                <div>
                                    <div className='w-full text-center flex flex-col items-center justify-center relative'>
                                        <img className='h-14' src={icon} alt="icon" />
                                        <h1 className='text-sm font-Roboto text-gray-50 mt-3 mb-1'>FILL REQUIREMENT</h1>
                                        <p className='text-xs font-Roboto text-gray-400'>It is a long established fact that a reader will be distracted by the readable</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='w-full text-center flex flex-col items-center justify-center'>
                                        <img className='h-14' src={icon} alt="icon" />
                                        <h1 className='text-sm font-Roboto text-gray-50 mt-3 mb-1'>GET INSTANT QUOTE</h1>
                                        <p className='text-xs font-Roboto text-gray-400'>It is a long established fact that a reader will be distracted by the readable</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='w-full text-center flex flex-col items-center justify-center'>
                                        <img className='h-14' src={icon} alt="icon" />
                                        <h1 className='text-sm font-Roboto text-gray-50 mt-3 mb-1'>BUILD DREAM HOME</h1>
                                        <p className='text-xs font-Roboto text-gray-400'>It is a long established fact that a reader will be distracted by the readable</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='w-full text-center flex flex-col items-center justify-center'>
                                        <img className='h-14' src={icon} alt="icon" />
                                        <h1 className='text-sm font-Roboto text-gray-50 mt-3 mb-1'>BUILD DREAM HOME</h1>
                                        <p className='text-xs font-Roboto text-gray-400'>It is a long established fact that a reader will be distracted by the readable</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className='col-span-7'>
                    {renderPageContent()}
                </div>
            </div>
        </div >
    )
}

export default EstimationIndex
