import React from 'react';
import HeroPart from './pages/HomePage/HeroPart/HeroPart';
import HowItWorksPart from './pages/HomePage/HowItWorks Part/HowItWorksPart';
import TestimonialPart from './pages/HomePage/TestimonalPart/TestimonialPart';
import ConstructionPackagesPart from './pages/HomePage/ConstructionPackagesPart/ConstructionPackagesPart';
import FeaturesIndex from './pages/HomePage/Features/FeaturesIndex';
import ArchitecturePlanIndex from './pages/HomePage/ArchitecturePlan/ArchitecturePlanIndex';
import FaqSection from './pages/HomePage/Faq/FaqSection';
import AboutUs from './pages/HomePage/About/AboutUs';
import ServiceSection from './pages/HomePage/services/ServiceSection';

function App() {
  return (
    <>
      <HeroPart />
      <HowItWorksPart />
      <AboutUs />
      <ArchitecturePlanIndex />
      <FeaturesIndex />
      <ServiceSection />
      <ConstructionPackagesPart />
      <FaqSection />
      <TestimonialPart />
    </>
  );
}

export default App;
