import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextInput } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { HiMail } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { verifyEmail } from '../../apiServices/api-functions/passwordset';
import image from '../../assets/images/passwordSet/Email.png'

function VerifyEmail() {
    const navigate = useNavigate()
    const [userData, setUserData] = useState('')
    const [emailData, setEmailData] = useState('')


    const onSubmit = async () => {
        const payload = {

        }
        const updateApi = await verifyEmail(`?user_id=${userData}&email=${emailData}`, payload)
        if (updateApi) {
            toast.success(updateApi?.data?.result?.msg)
            navigate(`/passwordSet/${emailData}`)
        }
    }

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setUserData(urlParams.get('userid'))
        setEmailData(urlParams.get('email'))
    }, []);
    return (
        <>
            <div
                className={'container relative z-10  block grid-cols-2 gap-2 xl:grid'}
            >
                <div
                    className="flex-col hidden min-h-screen xl:flex bg-blue-900"
                >
                    <div className="my-auto">
                        <img
                            alt="Midone Tailwind HTML Admin Template"
                            className="w-1/2 -mt-16 -intro-x ml-auto mr-auto"
                            src={image}
                        />
                        <div className="mt-10 text-4xl font-medium leading-tight text-white -intro-x text-center">
                            A few more clicks to <br />
                            sign up  your account.
                        </div>
                        <div className="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-slate-400 text-center">
                            Manage all your  accounts in one place
                        </div>
                    </div>
                </div>

                <div
                    className="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0 bg-blue-900 md:bg-white "
                >
                    <div
                        className="w-full px-5 py-8 mx-auto my-auto bg-white  rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto"
                    >
                        <h2 className="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left flex justify-center">
                            Verify your email address
                        </h2>
                        <div className="mt-2 text-black text-justify flex justify-center">
                            Please click the below button to complete
                            the verification process
                        </div>


                        <div className="mt-2 text-center intro-x  xl:text-left flex justify-center">
                            <Button
                                className="w-full align-top xl:w-32 xl:mr-3 bg-blue-900"
                                onClick={onSubmit}
                                type="button"
                            >
                                Verify E-Mail
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VerifyEmail
