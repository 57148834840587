import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getArchitecturePlan } from '../../../apiServices/api-functions/plansApi';
import lineImg from '../../../assets/images/Home Page/OurWorks/Frame 1597881896.png'
import { FaArrowRight } from 'react-icons/fa6';
import bgimage from "../../../assets/images/Home Page/ConstructionPackages/Vec.png"
import img2 from '../../../assets/images/features/Vector (1).png'
import RequirementModal from '../RequirementModal/RequirementModal';

function ArchitecturePlanIndex() {
    const [openRequirement, setOpenRequirement] = useState(false);

    const toggleModalRequirement = () => {
        setOpenRequirement(!openRequirement)
    }

    const getPlanData = useQuery({
        queryKey: ['getArchitecturePlan'],
        queryFn: () => getArchitecturePlan(),
    });

    console.log(getPlanData?.data?.data?.result?.rows);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className='relative px-[8%] py-7 lg:py-16'>
            <img src={bgimage} className='absolute top-0 left-0 -z-[50] w-[30%] md:w-[20%] opacity-45' />
            <div className='flex flex-col items-center justify-center'>
                <h2 className='text-[20px] sm:text-[34px] md:text-4xl font-bold font-Roboto text-navydark'>Architecture Plan</h2>
                <p className='mt-3 mb-2 font-Roboto text-center text-gray-500'>is a long established fact that a reader will be distracted by the readable. </p>
                <img src={lineImg} className='object-contain' alt="icon" />
            </div>
            {getPlanData?.data?.data?.result?.rows && (
                <>
                    <Carousel responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        arrows={false}
                        autoPlaySpeed={2500}
                        infinite={true}
                        className='w-full mt-3 py-5'>
                        {getPlanData?.data?.data?.result?.rows?.map((item, index) => (
                            <div
                                className="mx-3"
                                key={index}
                            >
                                <div className="p-2 shadow font-Roboto bg-white rounded-md">
                                    <div>
                                        <img
                                            className="h-52 w-full object-cover rounded-t-md"
                                            src={item?.img_url[0]}
                                            alt="design-icon"
                                        />
                                        <h1 className="bg-green-500 p-2 text-white rounded-b-md">
                                            PLAN ID:{' '}
                                            <span className="ms-1 text-gray-200">
                                                {item?.planNumber}
                                            </span>
                                        </h1>
                                    </div>
                                    <div className="py-3">
                                        <div className="flex justify-between items-center">
                                            <h1 className="text-primary text-base font-medium"></h1>
                                        </div>
                                        <div className="px-6 lg:px-14 mt-3 mb-3 flex justify-between items-center flex-wrap">
                                            <div>
                                                <h1 className="text-base">Sqft</h1>
                                                <p className="text-green-500">{item?.totalSqft}</p>
                                            </div>
                                            <div className="w-[1px] h-8 bg-gray-300"></div>
                                            <div>
                                                <h1 className="text-base text-center">Size</h1>
                                                <p className="text-orange-400 flex items-center">
                                                    {item?.length} X  {item?.width}
                                                </p>
                                            </div>
                                            <div className="w-[1px] h-8 bg-gray-300"></div>
                                            <div>
                                                <h1 className="text-base">Floors</h1>
                                                <p className="text-orange-400 text-center">
                                                    {item?.floors?.length}
                                                </p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="px-6 lg:px-14 mt-3 flex justify-between items-center flex-wrap">
                                            <div>
                                                <h1 className="text-base">Bed's</h1>
                                                <p className="text-green- text-center">
                                                    {item?.totalBhk ? item?.totalBhk : '-'}
                                                </p>
                                            </div>
                                            <div className="w-[1px] h-8 bg-gray-300"></div>
                                            <div>
                                                <h1 className="text-base text-center">Bath's</h1>
                                                <p className="text-warning text-center">
                                                    {item?.bathRoom ? item?.bathRoom : '-'}
                                                </p>
                                            </div>
                                            <div className="w-[1px] h-8 bg-gray-300"></div>
                                            <div>
                                                <h1 className="text-base">Kitchen</h1>
                                                <p className="text-warning text-center">
                                                    {item?.kitchen ? item?.kitchen : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex justify-between items-center border-t-[1px] border-gray-300 pt-3 px-1 mt-3">
                                            <h1 className="text-primary text-base font-medium">
                                                Approx:{' '}
                                                <span className="text-green-500 ms-1">
                                                    ₹{item?.planBudget}
                                                </span>
                                            </h1>
                                            <button
                                                onClick={toggleModalRequirement}
                                                className='p-1 px-2 lg:p-2 lg:px-4 rounded-md flex items-center font-medium bg-buttoncolor font-Roboto'
                                            >
                                                Get a Quote <span className='ms-1'><FaArrowRight /></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                    <div className='flex items-center justify-center mt-5'>
                        <button className='flex items-center bg-navydark p-2 px-10 text-white font-Roboto rounded-md'>See More <span className='ms-1'><FaArrowRight /></span></button>
                    </div>
                </>
            )}
            <RequirementModal isModal={openRequirement} toggle={toggleModalRequirement} count={'1'} />
        </div>
    )
}

export default ArchitecturePlanIndex
