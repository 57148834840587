import { apiFunctions } from "../ApiService";
import siteUrls from "../SiteURLs";



export const getPlanAndPackage = async () => {
    return apiFunctions.get(siteUrls.plans.index);
};

export const getArchitecturePlan = async () => {
    return apiFunctions.get(siteUrls.architecturePlan.get);
};