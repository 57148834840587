import { Dropdown, DropdownItem } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong, FaLocationDot } from "react-icons/fa6";
import { SlMenu } from "react-icons/sl";
import { IoClose } from "react-icons/io5";
import Aos from "aos";
import RequirementModal from "../../pages/HomePage/RequirementModal/RequirementModal";

const Navbar = () => {

  const [openRequirement, setOpenRequirement] = useState(false);
  const [windowwidth, setwindowwith] = useState(window.innerWidth)

  const [openToggle, setOpenToggle] = useState(false)
  const toggleModalRequirement = () => {
    setOpenRequirement(!openRequirement)
  }

  useEffect(() => {
    const handleresize = () => {
      setwindowwith(window.innerWidth)
    }
    window.addEventListener("resize", handleresize)

    Aos.init({
      duration: 1500
    })

    return () => {
      window.addEventListener("resize", handleresize)
    }
  }, [])

  const updateOpenToggle = () => {
    setOpenToggle(!openToggle)
  }

  function scrollToSection(id, height) {
    const section = document.getElementById(id);
    if (section) {
      const offset = section.getBoundingClientRect().top + window.pageYOffset - height;
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    }
  }

  return (
    <div className="fixed top-0 left-0 z-50 w-full ">
      <div className="p-2 md:p-4 backdrop-filter backdrop-blur-sm ">
      </div>
      <div className="px-[5%]">

        <div className="grid grid-cols-12 px-[3%] py-4 bg-navydark text-white rounded-xl shadow-md">


          <div className="flex items-center col-span-6 lg:col-span-2 2xl:col-span-2">
            <h2 onClick={() => scrollToSection('home')} className="cursor-pointer font-extrabold font-Roboto text-[16px] sm:text-[17px] lg:text-[16px] xl:text-[18px]">GET START BUILD</h2>
          </div>


          {windowwidth > 1024 ? (
            <>
              <div className="lg:col-span-8 2xl:col-span-8">

                <div className="mt-3">
                  <div className="grid grid-cols-12">

                    <div className="col-span-12">
                      <div className="flex items-center">
                        <ul className="flex items-center justify-center w-full lg:gap-x-5 xl:gap-x-8 2xl:gap-x-12 font-Roboto lg:text-sm xl:text-sm">
                          <li onClick={() => scrollToSection('home', 100)} className="cursor-pointer">
                            HOME
                          </li>
                          <li onClick={() => scrollToSection('howItWork', 100)} className="cursor-pointer">
                            HOW IT WORKS
                          </li>
                          <li onClick={() => scrollToSection('package', 100)} className="cursor-pointer">
                            SERVICES
                          </li>
                          <li onClick={() => scrollToSection('package', 100)} className="cursor-pointer">
                            PACKAGES
                          </li>
                          <li onClick={toggleModalRequirement} className="cursor-pointer">
                            CALCULATOR
                          </li>
                          <li onClick={() => scrollToSection('package', 100)} className="cursor-pointer">
                            CONTACT
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex justify-end overflow-hidden lg:col-span-2 xl:col-span-2 group" onClick={() => toggleModalRequirement()}>
                <button className="flex justify-center items-center w-[160px] lg:py-2 xl:py-3 rounded-[5px] bg-buttoncolor text-black hover:bg-white  transition-transform duration-300 ease-in-out hover:text-black font-Roboto font-medium xl:font-semibold lg:text-sm xl:text-base overflow-hidden">Get a Quote <FaArrowRightLong className="transition-transform duration-500 ease-in-out lg:ml-2 xl:ml-4 icon group-hover:translate-x-24" /></button>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-end col-span-6">
              <div className="cursor-pointer" >
                {!openToggle ? <SlMenu onClick={() => updateOpenToggle()} className="text-2xl" /> : <IoClose onClick={() => updateOpenToggle()} className="text-2xl" />}
              </div>
            </div>
          )}


        </div>

        {openToggle && (
          <div>
            <div className="relative w-full h-[100vh] z-[1000]">
              <div className="absolute top-0 left-0 w-full z-[10000]">
                <div data-aos="fade-down" className="w-full h-full py-5 mx-auto mt-1 text-white rounded-lg bg-navyverylight">
                  <ul className="flex flex-col items-center justify-center px-5 font-Roboto gap-y-5">
                    <li onClick={() => {
                      scrollToSection('home', 70)
                      setOpenToggle(false)
                    }} className="cursor-pointer w-full py-2 font-medium text-center rounded-md bg-gradient-to-tr from-navyverylight to-bglight text-navylight">
                      HOME
                    </li>
                    <li onClick={() => {
                      scrollToSection('howItWork', 70)
                      setOpenToggle(false)
                    }} className="cursor-pointer w-full py-2 font-medium text-center rounded-md bg-gradient-to-tr from-navyverylight to-bglight text-navylight">
                      HOW IT WORKS
                    </li>
                    <li onClick={() => {
                      scrollToSection('package', 70)
                      setOpenToggle(false)
                    }} className="cursor-pointer w-full py-2 font-medium text-center rounded-md bg-gradient-to-tr from-navyverylight to-bglight text-navylight">
                      SERVICES
                    </li>
                    <li onClick={() => {
                      scrollToSection('package', 70)
                      setOpenToggle(false)
                    }} className="cursor-pointer w-full py-2 font-medium text-center rounded-md bg-gradient-to-tr from-navyverylight to-bglight text-navylight">
                      PACKAGES
                    </li>
                    <li onClick={toggleModalRequirement} className="cursor-pointer w-full py-2 font-medium text-center rounded-md bg-gradient-to-tr from-navyverylight to-bglight text-navylight">
                      CALCULATOR
                    </li>
                    <li onClick={() => {
                      scrollToSection('package', 70)
                      setOpenToggle(false)
                    }} className="cursor-pointer w-full py-2 font-medium text-center rounded-md bg-gradient-to-tr from-navyverylight to-bglight text-navylight">
                      CONTACT
                    </li>
                    {/* <li className="flex items-center justify-center w-full py-2 font-medium rounded-md bg-gradient-to-tr from-navyverylight to-bglight text-navylight">
                      <FaLocationDot className="mr-1" />
                      <Dropdown label="vellore" inline >
                        <DropdownItem>Ranipet</DropdownItem>
                        <DropdownItem>Namakkal</DropdownItem>
                        <DropdownItem>Chennai</DropdownItem>
                        <DropdownItem>Tiruchi</DropdownItem>
                      </Dropdown>
                    </li> */}
                  </ul>
                  <button onClick={() => {
                    toggleModalRequirement()
                  }} className="mx-auto mt-5 flex justify-center items-center w-[120px]  sm:w-[170px] py-[5px] lg:py-2 xl:py-3 rounded-[5px] group bg-buttoncolor text-black hover:bg-white  transition-transform duration-300 ease-in-out hover:text-black font-Roboto font-medium xl:font-semibold lg:text-sm xl:text-base overflow-hidden">Get a Quote <FaArrowRightLong className="ml-1 text-lg transition-transform duration-500 ease-in-out sm:text-xl lg:ml-2 xl:ml-4 icon group-hover:translate-x-24" /></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <RequirementModal isModal={openRequirement} toggle={toggleModalRequirement} count={'1'} />
    </div>
  );
};

export default Navbar;
