import React from 'react'
import quotesimg from "../../../assets/images/Home Page/Testimonial/Mask group (3).png"
import bgimg from "../../../assets/images/Home Page/Testimonial/Group 1597881641.png"
import bgimg2 from "../../../assets/images/Home Page/Testimonial/Mask group (2).png"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./TestimonialPart.css"
import image1 from "../../../assets/images/Home Page/Testimonial/sasibalan.jpg"
import image2 from "../../../assets/images/Home Page/Testimonial/palini2.jpg"
import image3 from "../../../assets/images/Home Page/Testimonial/karthick2.jpg"
import image4 from "../../../assets/images/Home Page/Testimonial/image4.png"
import { FaArrowRightLong } from 'react-icons/fa6'

const TestimonialPart = () => {

  const testimonaldata = [
    {
      id: 1,
      para: "I noticed one thing: it's very productive to discuss a project with pople who have technical background. I forgot how to explain obvious technical things that often happen with more humanitarian people and just get more done.",
      img: `${image3}`,
      title: "Karthick",
      title2: "customer"
    },
    {
      id: 2,
      para: "I noticed one thing: it's very productive to discuss a project with pople who have technical background. I forgot how to explain obvious technical things that often happen with more humanitarian people and just get more done.",
      img: `${image2}`,
      title: "Palani Kumar",
      title2: "customer"
    },
    {
      id: 3,
      para: "I noticed one thing: it's very productive to discuss a project with pople who have technical background. I forgot how to explain obvious technical things that often happen with more humanitarian people and just get more done.",
      img: `${image1}`,
      title: "Sasi Balan",
      title2: "customer"
    },
    {
      id: 4,
      para: "I noticed one thing: it's very productive to discuss a project with pople who have technical background. I forgot how to explain obvious technical things that often happen with more humanitarian people and just get more done.",
      img: `${image4}`,
      title: "axel",
      title2: "customer"
    },
  ]

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className='relative '>
      <img src={bgimg} className='absolute top-0 left-0 w-full h-full -z-10' alt="" />

      <div className='grid grid-cols-1 lg:grid-cols-2 px-[8%]'>
        {/* left side */}
        <div className='py-5 text-white lg:py-16'>

          <div className=''>
            <img src={quotesimg} className='w-24' alt="" />

            <div className='mt-16 mb-10 md:mb-16 font-Roboto '>
              <h5 className='font-medium text-[15px]'>WHAT PEOPLE SAY</h5>
              <h1 className='mt-5 text-4xl font-medium mb-7'>Clients Talk</h1>
              <p className='font-medium text-[15px]  w-[100%] md:w-[490px] '>We have a wealth of experience working as main building contractors on all kinds of projects, big and small, from home maintenance and improvements to extensions, refurbishments and new builds.</p>
            </div>

            <button className='flex items-center justify-center px-5 py-2 overflow-hidden font-medium text-black transition-transform duration-300 ease-in-out rounded-md font-Roboto bg-yellowcolor group hover:bg-white hover:text-black'>VIEW MORE <FaArrowRightLong className='ml-3 transition-transform duration-500 ease-in-out group-hover:translate-x-24' /></button>
          </div>

        </div>

        {/* rightside */}
        <div className='flex items-center justify-center py-5 text-black md:py-2 8'>

          <Carousel responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={false}
            autoPlaySpeed={2500}
            infinite={true}
            className='w-full h-[350px]'>

            {testimonaldata?.map((data, id) => {
              return <div key={id} className=' bg-white diagram w-[95%] md:w-[95%] h-[400px] text-black font-Roboto'>
                <div className='absolute top-[25px] right-4 md:right-12 '>
                  <img src={bgimg2} className='h-28 md:h-32 ' alt="" />
                </div>
                <p className='text-[13.5px] sm:text-[15px] pt-16 sm:pt-24 md:pt-20 lg:pt-[88px] px-5 sm:px-8'>{data?.para}</p>
                <div className='flex items-center pt-3 md:pt-4 ps-5 md:ps-11'>
                  <img src={data.img} className='object-cover w-20 h-20 rounded-full' alt="" />
                  <div className='ml-3'>
                    <h2 className='text-xl font-semibold text-navydark'>{data.title}</h2>
                    <h2 className='text-[#484848] font-medium'>{data.title2}</h2>
                  </div>
                </div>
              </div>
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default TestimonialPart