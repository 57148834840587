import React, { useState } from 'react'
import img1 from '../../../assets/images/features/Vector.png'
import img2 from '../../../assets/images/features/Vector (1).png'
import lineImg from '../../../assets/images/Home Page/OurWorks/Frame 1597881896.png'
import { FaMinus, FaPlus } from 'react-icons/fa6'

function FaqSection() {

    const [selectedFaq, setSelectedFaq] = useState(1)

    const mockData = [
        {
            title: 'What are the benefits of installing solar panels ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What maintenance is required for solar panels ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Why is renewable energy important ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Can i use solar panels to power my entire home ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Do i need batteries to store solar energy ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What are the benefits of installing solar panels ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
    ]
    return (
        <div className='relative px-[8%] py-7 lg:py-16'>
            <img className='absolute w-40 left-0 top-0 opacity-45' src={img1} alt="icon" />
            <img className='absolute w-28 right-0 top-0 opacity-45' src={img2} alt="icon" />
            <div className='flex flex-col items-center justify-center'>
                <h2 className='text-[20px] sm:text-[34px] md:text-4xl font-bold font-Roboto text-navydark'>FAQ</h2>
                <p className='mt-3 mb-2 text-center font-Roboto text-gray-500'>is a long established fact that a reader will be distracted by the readable. </p>
                <img src={lineImg} className='object-contain' alt="icon" />
            </div>
            <div className='lg:px-[16%] flex flex-col justify-center items-center mt-5'>
                {mockData?.map((item, i) => (
                    <div
                        key={i}
                        className={`w-full shadow px-3 py-5 rounded-md mb-5 border-2 border-gray-300 transition-all duration-300 ${selectedFaq === i + 1 ? 'bg-navyverylight text-white border-none' : ''
                            }`}
                        style={{
                            transition: 'background-color 0.2s easeIn, color 0.3s easeIn',
                        }}
                    >
                        <div
                            onClick={() => setSelectedFaq(selectedFaq === i + 1 ? null : i + 1)}
                            className='flex justify-between items-center cursor-pointer'
                        >
                            <h1
                                className={`font-Roboto text-lg transition-colors duration-100 ${selectedFaq === i + 1 ? 'text-white' : ''
                                    }`}
                            >
                                {item?.title}
                            </h1>
                            <h1
                                className={`text-navydark transition-colors duration-100 ms-8 lg:ms-16 ${selectedFaq === i + 1 ? 'text-yellowBtn' : ''
                                    }`}
                            >
                                {selectedFaq === i + 1 ? <FaMinus /> : <FaPlus />}
                            </h1>
                        </div>
                        <div
                            className={`mt-2 w-3/4 transition-all duration-200 ${selectedFaq === i + 1 ? 'block' : 'hidden'
                                }`}
                        >
                            <p className='text-gray-400'>{item?.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FaqSection
