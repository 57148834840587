import React, { useEffect, useState } from "react";
import leftsideimage from "../../../assets/images/Home Page/HeroPart/leftsideimage1.png";
import icon1 from "../../../assets/images/Home Page/HeroPart/icons/11Vector.png"
import icon2 from "../../../assets/images/Home Page/HeroPart/icons/EXP.png"
import icon3 from "../../../assets/images/Home Page/HeroPart/icons/PRO.png"
import { mobileOTPSignUpVerify, mobileOTPSignup } from "../../../apiServices/api-functions/authApi";
import bgimg1 from "../../../assets/images/Home Page/HeroPart/Frame 1597881890.png"
import bgimg2 from "../../../assets/images/Home Page/HeroPart/Ellipse 8589.png"
import playicon from "../../../assets/images/Home Page/HeroPart/gridicons_play.png"
import OtpModal from "../../../components/Modal/OtpModal";
import toast from "react-hot-toast";
import { FaArrowRightLong } from "react-icons/fa6";
import CountUp from "react-countup";
import RequirementModal from "../RequirementModal/RequirementModal";
import { Button, Modal } from "flowbite-react";
import OTPInput from "react-otp-input";

const HeroPart = () => {
  const [otp, setOtp] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openRequirement, setOpenRequirement] = useState(false);
  const [mobileOtp, setMobileOtp] = useState('');
  const [stepCount, setStepCount] = useState('');
  const [windowWidth, setWindowWidth] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal)
  }
  const toggleModalRequirement = () => {
    setOpenRequirement(!openRequirement)
  }

  //Mobile OTP send
  const mobileOtpSend = async (event) => {
    const payload = {
      mobile: mobileOtp
    }
    if (mobileOtp.length === 10) {
      const updateApi = await mobileOTPSignup(payload)
      if (updateApi) {
        toast.success('OTP send Successfully')
        setOpenModal(true)
      }
    }
  }

  const otpVerify = async () => {
    const payload = {
      mobile: mobileOtp,
      otp
    }
    const updateApi = await mobileOTPSignUpVerify(payload)
    if (updateApi.data.result.requirementStep !== 4) {
      toast.success('OTP Verified Successfully')
      const data = updateApi.data.result;
      localStorage.setItem('access-token', data.tokens.accessToken);
      localStorage.setItem('role', btoa(data.user.role.name));
      localStorage.setItem('refreshToken', data.tokens.refreshToken);
      localStorage.setItem('userId', data.user._id);
      setStepCount(data?.requirementStep)
      setOtp('')
      toggleModal()
      toggleModalRequirement()
    } else {
      toggleModal()
      const { accessToken, refreshToken } = updateApi?.data?.result?.tokens;
      const roleName = updateApi?.data?.result?.user?.role?.name;

      // Construct the URL with query parameters
      const newUrl = `https://devapp.getstartbuild.com/reconnect/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}`;

      // Open the URL in a new tab
      window.open(newUrl, '_blank');
    }
  }

  {/*for large screen*/ }
  useEffect(() => {
    const handleresize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleresize)

    return () => {
      window.addEventListener("resize", handleresize)
    }
  }, [])

  return (
    <>
      <div id="home" className="h-full lg:h-screen flex items-center justify-center bg-[#F9F9F9] w-full relative">

        <img src={bgimg1} className="absolute z-auto top-[70px] sm:top-24 2xl:top-32 left-6 sm:left-12 md:left-14 lg:left-16 xl:left-20 2xl:left-24" alt="" />
        <img src={bgimg2} className="absolute bottom-0 left-0" alt="" />

        <div className={`grid grid-cols-12  pt-4 lg:pt-[115px] xl:pt-[145px] px-[2%] lg:px-[4%] ${windowWidth > 1536 ? " gap-x-10 w-[90%] px-0" : "w-full  "}`}>

          <div className={`z-10 w-full col-span-12 px-0 lg:h-full lg:col-span-7 xl:col-span-7 pt-14 2xl:col-span-6 `}>
            <div className="relative w-full lg:h-[90%]  xl:h-[90%]">
              <img src={leftsideimage} className="w-full h-full " alt="icon" />
              <img src={playicon} className="w-16 sm:w-24 md:w-32 absolute top-[50%] left-[50%] tranform translate-x-[-50%] translate-y-[-50%]" alt="" />
            </div>
          </div>


          <div className={`w-full lg:h-screen  col-span-12 lg:col-span-5 xl:col-span-5 z-10 pb-5  2xl:col-span-6 ${windowWidth > 1536 ? "pt-[120px] " : "lg:pt-[109px]"}`}>
            <div className="-ml-1 sm:ml-12 lg:ml-0 px-[8%] sm:px-0">
              <div className="font-Inter">

                <h2 className={`mb-3  font-semibold  leading-[1] ${windowWidth > 1536 ? "text-[70px]" : "text-[30px] sm:text-[45px] md:text-[50px] lg:text-[45px] 2xl:text-[54px]"} `}>State Your <span className="text-[#FB6514]">Dream</span></h2>

                <h2 className={` leading-[1] font-semibold   ${windowWidth > 1536 ? "text-[70px] mb-5" : "text-[30px] sm:text-[45px] md:text-[50px] lg:text-[45px] 2xl:text-[54px] mb-3"}`}>To Build A Future</h2>

                <p className={` leading-[24px]  text-[#484848] font-semibold  ${windowWidth > 1536 ? "text-[20px] mb-10" : "sm:text-[14.5px]  2xl:text-[16px]  mb-4 sm:mb-4 xl:mb-8"}`}>Creating quality Lifestyles, building stronger communities</p>

                <div className={`relative ${windowWidth > 1536 ? "w-[70%]" : "w-full sm:w-[70%] lg:w-[80%] xl:w-[78%]"}  `}>
                  <input onChange={(e) => setMobileOtp(e.target.value)} type="number" className={`w-full placeholder:text-base  font-medium text-xl border-[1px] border-navydark rounded-lg sm:rounded-[46px] ${windowWidth > 1536 ? "py-5 px-7" : "py-4 px-2 sm:px-4 2xl:px-6"} `} placeholder="Enter Your Number" />
                  <button onClick={mobileOtpSend} disabled={!mobileOtp || mobileOtp.length !== 10} className={`absolute   flex items-center font-semibold text-black  bg-buttoncolor rounded-lg sm:rounded-[46px] overflow-hidden group   right-0 top-[50%] transform translate-x-[-5%] translate-y-[-50%] ${windowWidth > 1536 ? "py-[16px] px-8 text-xl" : "py-[10px] px-4 sm:px-6 text-lg"}`}>Submit <FaArrowRightLong className="ml-1 transition-transform duration-500 ease-in-out lg:ml-2 xl:ml-4 icon group-hover:translate-x-24" /></button>
                </div>

              </div>

              <div className="mt-5 sm:mt-6 xl:mt-10">
                <div className={`grid mx-auto sm:grid-cols-2 ${windowWidth > 1536 ? "gap-y-12" : " gap-y-8 "}`}>
                  <div className="flex transition-all duration-300 ease-in hover:scale-105">
                    <img src={icon3} className={` rounded-lg bg-[#0984E3] py-3 px-3 ${windowWidth > 1536 ? "w-[85px]" : "w-[70px]"}`} alt="" />
                    <div className="flex flex-col items-start justify-center ml-3 font-Roboto">
                      <div className={` font-bold flex items-center ${windowWidth > 1536 ? "text-4xl" : "text-2xl"}`}>
                        <CountUp duration={2} end={1400} />
                        <span className="ml-1 text-xl 2xl:text-2xl"> +</span></div>
                      <h2 className="text-[#484848] ">Projects Completed</h2>
                    </div>
                  </div>
                  <div className="flex transition-all duration-300 ease-in hover:scale-105">
                    <img src={icon2} className={`  rounded-lg bg-[#FF483C] py-3 px-3 ${windowWidth > 1536 ? "w-[85px]" : "w-[70px]"}`} alt="" />
                    <div className="flex flex-col items-start justify-center ml-3 font-Roboto">
                      <div className={` font-bold flex items-center ${windowWidth > 1536 ? "text-4xl" : "text-2xl"}`}>
                        <CountUp duration={2} end={1990} />
                        <span className="ml-1 text-xl 2xl:text-2xl"> +</span></div>
                      <h2 className="text-[#484848] ">Experience builder</h2>
                    </div>
                  </div>
                  <div className="flex transition-all duration-300 ease-in hover:scale-105">
                    <img src={icon1} className={` rounded-lg bg-[#08CF65] py-2 px-3 ${windowWidth > 1536 ? "w-[85px]" : "w-[70px]  h-[70px]"}`} alt="" />
                    <div className="flex flex-col items-start justify-center ml-3 font-Roboto">
                      <div className={` font-bold flex items-center ${windowWidth > 1536 ? "text-4xl" : "text-2xl"}`}>
                        <CountUp duration={2} end={3000} />
                        <span className="ml-1 text-xl 2xl:text-2xl"> +</span></div>
                      <h2 className="text-[#484848] ">Secure Transaction</h2>
                    </div>
                  </div>
                  <div className="flex transition-all duration-300 ease-in hover:scale-105">
                    <img src={icon1} className={` rounded-lg bg-[#08CF65] py-2 px-3 ${windowWidth > 1536 ? "w-[85px]" : "w-[70px]  h-[70px]"}`} alt="" />
                    <div className="flex flex-col items-start justify-center ml-3 font-Roboto">
                      <div className={` font-bold flex items-center ${windowWidth > 1536 ? "text-4xl" : "text-2xl"}`}>
                        <CountUp duration={2} end={1000} />
                        <span className="ml-1 text-xl 2xl:text-2xl"> +</span></div>
                      <h2 className="text-[#484848] ">Secure Transaction</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Modal show={openModal} style={{ width: 'auto' }} onClose={toggleModal} popup>
          {/* <Modal.Header /> */}
          <Modal.Body>
            <div className="text-center p-6">
              <h1 className='text-2xl mb-3 font-bold'>OTP Verification</h1>
              <h3 className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
                Enter the OTP you received at <span className='text-navydark font-bold'>+91 {mobileOtp}</span>
              </h3>
              <div className='flex justify-center'>
                <OTPInput
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  inputType="tel"
                  numInputs={6}
                  inputStyle="custom-otp-field"
                  renderInput={(props) => <input {...props} />}
                />

              </div>
              <div className="flex justify-center mt-5">
                <Button color="dark" size='sm' disabled={!otp || otp.length !== 6} onClick={otpVerify}>
                  Verify
                </Button>
              </div>
              <p className='text-sm mt-3 text-gray-500'>Didn't receive code? <span className='text-teal-600 underline cursor-pointer'>Request again</span></p>
            </div>
          </Modal.Body>
        </Modal >
        {/* <OtpModal isModal={openModal} toggle={toggleModal} mobile={mobileOtp} page={true} /> */}
        <RequirementModal isModal={openRequirement} toggle={toggleModalRequirement} count={stepCount} />
      </div >


    </>
  );
};

export default HeroPart;
