import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import {
    Controller,
    SubmitHandler,
    useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { Button, Label, Spinner, TextInput, Tooltip } from 'flowbite-react';
import { FaArrowLeft, FaArrowRight, FaCircleCheck, FaUser } from 'react-icons/fa6';
import { HiMail } from 'react-icons/hi';
import { FaArrowAltCircleLeft, FaArrowCircleLeft, FaMapMarked, FaMapMarker, FaPhoneAlt } from 'react-icons/fa';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getCity } from '../../../../../apiServices/api-functions/locationApi';
import './formStyle.css'
import { stepOneGET, stepOnePincodeGET, stepOneUpdate, stepOneUpdateAgain } from '../../../../../apiServices/api-functions/requirementApi';
import toast from 'react-hot-toast';
import { mobileOTPSignUpVerify, mobileOTPSignup } from '../../../../../apiServices/api-functions/authApi';
import OTPInput from 'react-otp-input';

function StepOneEstimation({ nextPage, toggle }) {
    const [otp, setOtp] = useState();
    const [isOtp, setIsOtp] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [search, setSearch] = useState('');
    const queryClient = useQueryClient();
    const [searchPermAddress, setSearchPermAddress] = useState('');
    const [showPermAddress, setShowPermAddress] = useState(false);
    const [setPermAddressData, setSetPermAddressData] = useState();

    const BasicFormSchema = yup.object().shape({
        email: yup.string().email().required('Please enter your email.'),
        name: yup.string().required('Please enter your name.'),
        mobile: yup.string().required('Please enter your mobile.'),
        location: yup.mixed().optional('Please enter your location'),
    });

    const { control, handleSubmit, setValue, getValues, formState: { errors }, register } = useForm({
        resolver: yupResolver(BasicFormSchema),
        defaultValues: {
        }
    });

    const getBasicDetails = useQuery({
        queryKey: ['getBasicDetail'],
        queryFn: () => stepOneGET()
    })

    const getPincode = useQuery({
        queryKey: ['getPincode', searchPermAddress],
        queryFn: () => stepOnePincodeGET(`search=${searchPermAddress}`)
    })

    const cityAPI = useQuery({
        queryKey: ['city'],
        queryFn: () => getCity('64215a1f8622ec91f4b5e98e'),
    });

    const options = getPincode?.data?.data?.result?.map((item) => (
        {
            label: item?.name,
            value: item?._id
        }
    ))

    const handlePermAddress = (event) => {
        setSearchPermAddress(event.target.value);
        setShowPermAddress(true);
    };
    const handleClickPermAddress = (option) => {
        setSetPermAddressData(option);
        setShowPermAddress(false);
    };

    const userId = localStorage.getItem('userId')

    const onSubmit = async (data) => {
        const payload = {
            ...data,
            location: setPermAddressData?._id
        }
        if (getBasicDetails?.data?.data?.result?.email) {
            const updateApi = await stepOneUpdateAgain(userId, payload)
            if (updateApi) {
                toast.success('Basic Details Updated')
                nextPage()
                queryClient.invalidateQueries([
                    'stepRequirement',
                ]);
            }
        } else {
            const updateApi = await stepOneUpdate(payload)
            if (updateApi) {
                toast.success('Basic Details Updated')
                nextPage()
                queryClient.invalidateQueries([
                    'stepRequirement',
                ]);
            }
        }

    };

    const handleProceedToNext = () => {
        handleSubmit(onSubmit)();
    };


    //Mobile OTP send
    const mobileOtpSend = async (number) => {
        setMobileNumber(number)
        const payload = {
            mobile: number
        }
        if (number.length === 10) {
            const updateApi = await mobileOTPSignup(payload)
            if (updateApi) {
                toast.success('OTP send Successfully')
                setIsOtp(true)
            }
        }
    }

    const otpVerify = async () => {
        const payload = {
            mobile: mobileNumber,
            otp
        }
        const updateApi = await mobileOTPSignUpVerify(payload)
        console.log(updateApi?.data?.result);
        if (updateApi.data.result.requirementStep !== 4) {
            toast.success('OTP Verified Successfully')
            const data = updateApi.data.result;
            localStorage.setItem('access-token', data.tokens.accessToken);
            localStorage.setItem('role', btoa(data.user.role.name));
            localStorage.setItem('refreshToken', data.tokens.refreshToken);
            localStorage.setItem('userId', data.user._id);
            setOtp('')
            setIsOtp(false)
            queryClient.invalidateQueries([
                'stepRequirement',
            ]);
        } else {
            toggle()
            const { accessToken, refreshToken } = updateApi?.data?.result?.tokens;
            const roleName = updateApi?.data?.result?.user?.role?.name;

            // Construct the URL with query parameters
            const newUrl = `https://devapp.getstartbuild.com/reconnect/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}`;

            // Open the URL in a new tab
            window.open(newUrl, '_blank');
        }
    }

    useEffect(() => {
        if (getBasicDetails?.data?.data?.result?.mobile) {
            setValue('mobile', getBasicDetails?.data?.data?.result?.mobile)
        }
        if (getBasicDetails?.data?.data?.result?.name) {
            setValue('name', getBasicDetails?.data?.data?.result?.name)
            setValue('email', getBasicDetails?.data?.data?.result?.email)
            setValue('location', { label: getBasicDetails?.data?.data?.result?.location?.name, value: getBasicDetails?.data?.data?.result?.location?._id })
        }
    }, [getBasicDetails?.data?.data?.result])


    return (
        <>
            {isOtp ? (
                <div className='flex justify-center items-center'>
                    <div className="text-center p-6">

                        <div className='flex items-center justify-center'>
                            <Tooltip content="Go back">
                                <h1 className='text-[20px] mb-3 me-1 cursor-pointer' onClick={() => setIsOtp(false)}><FaArrowLeft /></h1>
                            </Tooltip>
                            <h1 className='text-2xl mb-3 font-bold'> OTP Verification</h1>
                        </div>
                        <h3 className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
                            Enter the OTP you received at <br /><span className='text-navydark font-bold'>+91 {mobileNumber}</span>
                        </h3>
                        <div className='flex justify-center'>
                            <OTPInput
                                value={otp}
                                onChange={(e) => setOtp(e)}
                                inputType="tel"
                                numInputs={6}
                                inputStyle="custom-otp-field"
                                renderInput={(props) => <input {...props} />}
                            />

                        </div>
                        <div className="flex justify-center mt-5">
                            <Button color="dark" size='sm' disabled={!otp || otp.length !== 6} onClick={otpVerify}>
                                Verify
                            </Button>
                        </div>
                        <p className='text-sm mt-3 text-gray-500'>Didn't receive code? <span className='text-teal-600 underline cursor-pointer'>Request again</span></p>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col justify-between h-full'>
                    <div>
                        <div>
                            <h1 className='font-medium text-xl text-gray-800 font-Roboto'>Basic Details</h1>
                            <p className='text-xs text-gray-400 mt-1 mb-5 font-Roboto'>Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's.</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='grid grid-cols-12 gap-4 gap-y-4'>
                                <div className='col-span-12'>
                                    <div className="max-w-md">
                                        <TextInput id="name" type="text" icon={FaUser} {...register("name")} placeholder="Enter Your Name" required />
                                    </div>
                                </div>
                                <div className='col-span-12'>
                                    <div className="max-w-md">
                                        <TextInput id="email" type="email" icon={HiMail} {...register("email")} placeholder="Enter Your Email" required />
                                    </div>
                                </div>
                                <div className='col-span-12'>
                                    <div className="max-w-md relative">
                                        <TextInput id="mobile" type="number" icon={FaPhoneAlt} {...register("mobile")} onChange={(e) => mobileOtpSend(e.target.value)} placeholder="Enter You Mobile +91" required />
                                        <h1 className='text-green-500 text-[25px] absolute right-[14px] top-2'><FaCircleCheck /></h1>
                                    </div>
                                </div>
                                {/* <div className='col-span-12'>
                                    <div>
                                        <Controller
                                            name='location'
                                            id='location'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                            (
                                                <Select
                                                    type="select"
                                                    {...field}
                                                    placeholder='Select your location'
                                                    options={options}
                                                    invalid={errors.location && true} >
                                                </Select>
                                            )} />
                                        {errors.location && <p>{errors.location.message}</p>}
                                    </div>
                                </div> */}
                                <div className='col-span-12'>
                                    <div>
                                        <TextInput
                                            name='location'
                                            id='location'
                                            type="text"
                                            className="mt-0.5"
                                            placeholder="PinCode"
                                            onChange={handlePermAddress}
                                            defaultValue={setPermAddressData?.pincode}
                                            onFocus={() => setShowPermAddress(true)}
                                            icon={FaMapMarker}

                                        />
                                        {showPermAddress && (
                                            <div className="box h-52 overflow-y-auto intro-y">
                                                {getPincode?.data?.data?.result?.map(
                                                    (option) => (
                                                        <div
                                                            key={option._id}
                                                            className="cursor-pointer intro-x box bg-slate-100 rounded-none p-2 my-1"
                                                            onClick={() => handleClickPermAddress(option)}
                                                        >
                                                            <div className="flex">
                                                                <div className="ml-1">
                                                                    <p className="font-medium">
                                                                        {option?.pincode}
                                                                    </p>
                                                                    <p className="text-xs text-slate-500">
                                                                        {option?.areaName}
                                                                        <span>,{option?.district},</span>
                                                                    </p>
                                                                    <p>{option?.state}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='flex justify-end'>
                        <div>
                            <button className='next-btn' onClick={handleProceedToNext}>Proceed to Next <span className='ms-1 pt-[3px]'><FaArrowRight /></span></button>
                        </div>
                    </div>
                </div>
            )}

        </>

    )
}

export default StepOneEstimation
