import configJson from '../config/Config';

const siteUrlsJson = {
    ourSiteUrls: {
        auth: {
            mobileSignup: 'otp/send/visitor',
            mobileSignupVerify: 'otp/verify',
        },
        location: {
            country: 'country/',
            state: 'state/',
            city: 'auth/city/',
        },
        requirement: {
            plans: 'auth/plans',
            construction: 'auth/constructionType',
            additional: 'auth/additionalFeatures',
            requirement: 'requirement',
            visitor: 'visitor',
            step: 'auth/requirementStep',
            form: {
                stepOne: {
                    create: 'visitor/form',
                    get: 'visitor',
                    pincodeDrop: 'pincode',
                },
                stepTwo: {
                    create: 'constructionDetails',
                    get: 'requirement',
                    getFloor: 'auth/floor',
                },
                stepThree: {
                    tab: 'auth/floorTab',
                    count: 'floorCount',
                    floor: 'floorDetails',
                    room: 'auth/roomName',
                    update: 'submitFloorDetails'
                },
                stepFour: {
                    update: 'additionalRequirement',
                },
                stepFive: {
                    update: 'loanRequired',
                    final: 'submitRequirement',
                    nav: 'webToDashboard',
                }
            }
        },
        plans: {
            index: 'auth/getPlanAndPackage'
        },
        architecturePlan: {
            get: 'auth/architectureDesign'
        },
        passwordSet: {
            verifyEmail: 'auth/user-accept',
            setPassword: 'auth/user-setPassword'
        }
    },
    outerDomainUrls: {},
};
function checkInnerJson(jsonData) {
    if (jsonData) {
        for (const key in jsonData) {
            if (typeof jsonData[key] === 'string') {
                jsonData[key] = `${configJson.backendDomain}${jsonData[key]}`;
            } else {
                jsonData[key] = checkInnerJson(jsonData[key]);
            }
        }
    }
    return jsonData;
}

const siteUrls = {
    ...checkInnerJson(siteUrlsJson.ourSiteUrls),
    outerDomainUrls: siteUrlsJson.outerDomainUrls,
};
export default siteUrls;
