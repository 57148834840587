import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';

function axiosErrorResponse(error) {
    let msg = '';
    if (error.response) {
        msg = error.response.data.message || error.response.data.error || error.response.data.msg;
    } else if (error.request) {
        msg = error.request;
    } else {
        msg = error.message;
    }
    return msg;
}

axios.interceptors.response.use(
    (response) => new Promise((resolve) => resolve(response)),
    (error) => {
        if (error.response.status === 422) {
            toast.error(error.response.data?.error?.message);
        }
        if (error.response.status === 400) {
            toast.error(error.response.data?.msg);
        }
        if (!error.response) {
            return new Promise((_, reject) => reject(error));
        }
        if (error.response.status === 401) {
            return new Promise((_, reject) => reject(error));
        } else {
            return new Promise((_, reject) => reject(error));
        }
    }
);

function setToken(token, refreshToken) {
    if (token && refreshToken) {
        localStorage.setItem('access-token', token);
        localStorage.setItem('refresh-token', refreshToken);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }
}

function removeToken() {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
}

function getHeaders() {
    const accessToken = localStorage.getItem('access-token');
    if (accessToken) {
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
    }
    return undefined;
}

const apiFunctions = {
    get: async (url) => axios.get(url, getHeaders()),
    post: async (url, data) => axios.post(url, data, getHeaders()),
    put: async (url, data) => axios.put(url, data, getHeaders()),
    patch: async (url, data) => axios.patch(url, data, getHeaders()),
    delete: async (url) => axios.delete(url, getHeaders()),
    uploadFile: async (url, data) => {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access-token')}`,
                'Content-Type': 'multipart/form-data',
            },
        };
        return axios.post(url, data, headers);
    },
};

function isTokenValid(token) {
    try {
        const decodedJwt = jwtDecode(token);
        return decodedJwt || false; // unlimited expiry
    } catch (error) {
        return false;
    }
}

export {
    axios,
    apiFunctions,
    setToken,
    removeToken,
    isTokenValid,
    axiosErrorResponse,
};
