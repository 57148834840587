import dayjs from "dayjs";

const cutText = (text, length) => {
    if (text.split(' ').length > 1) {
        const string = text.substring(0, length);
        const splitText = string.split(' ');
        splitText.pop();
        return splitText.join(' ') + '...';
    } else {
        return text;
    }
};

const capitalizeFirstLetter = (string) => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return '';
    }
};

const formatCurrency = (number) => {
    if (number) {
        const formattedNumber = number.toString().replace(/\D/g, '');
        const rest = formattedNumber.length % 3;
        let currency = formattedNumber.substr(0, rest);
        const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
        let separator;

        if (thousand) {
            separator = rest ? '.' : '';
            currency += separator + thousand.join('.');
        }

        return currency;
    } else {
        return '';
    }
};

const timeAgo = (time) => {
    const date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' '));
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
        return dayjs(time).format('MMMM DD, YYYY');
    }

    return (
        (dayDiff === 0 &&
            ((diff < 60 && 'just now') ||
                (diff < 120 && '1 minute ago') ||
                (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
                (diff < 7200 && '1 hour ago') ||
                (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
        (dayDiff === 1 && 'Yesterday') ||
        (dayDiff < 7 && dayDiff + ' days ago') ||
        (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    );
};

const randomNumbers = (from, to, length) => {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
        numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
};

const getUserRole = () => {
    const roleValue = localStorage.getItem('role');
    if (roleValue) {
        return atob(roleValue);
    }
    return roleValue;
};

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}


export {
    capitalizeFirstLetter,
    formatCurrency,
    timeAgo,
    randomNumbers,
    getUserRole,
    objectToQueryString,
    cutText,
};