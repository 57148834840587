import React from 'react'
import lineImg from '../../../assets/images/Home Page/OurWorks/Frame 1597881896.png'
import img1 from '../../../assets/images/Home Page/OurWorks/Group 1597881653.png'
import img2 from '../../../assets/images/Home Page/OurWorks/Flat.png'
import img3 from '../../../assets/images/Home Page/OurWorks/Group 1597881658.png'
import img4 from '../../../assets/images/Home Page/OurWorks/house_619032 1.png'
import bgimg from "../../../assets/images/Home Page/OurWorks/Rectangle 34625210 (1).png"
import bgimage1 from "../../../assets/images/Home Page/OurWorks/Vector (9).png"

const HowItWorksPart = () => {

  const data = [
    {
      img: img1,
      title: 'fill Requirement',
      description: 'Thoroughly filled proposal form encompassing all construction project details, from scope and objectives to budget and timeline'
    },
    {
      img: img3,
      title: 'Get proposal',
      description: 'Thoroughly filled proposal form encompassing all construction project details, from scope and objectives to budget and timeline'
    },
    {
      img: img2,
      title: 'Project tracking',
      description: 'Thoroughly filled proposal form encompassing all construction project details, from scope and objectives to budget and timeline'
    },
    {
      img: img4,
      title: 'Dream Home',
      description: 'Thoroughly filled proposal form encompassing all construction project details, from scope and objectives to budget and timeline'
    },
  ]
  return (
    <div id='howItWork' className='relative'>

      <img src={bgimage1} className='absolute top-0 right-0' alt="" />
      <div className='py-[3%] px-[5%] '>
        <div className='flex flex-col items-center justify-center'>
          <h2 className='text-[20px] sm:text-[34px] md:text-4xl font-bold font-Roboto text-navydark'>How It Works</h2>
          <p className='mt-3 mb-2 text-center font-Roboto text-gray-500'>is a long established fact that a reader will be distracted by the readable. </p>
          <img src={lineImg} className='object-contain' alt="icon" />
        </div>
        <div className='grid grid-cols-12 mt-14 gap-y-10 sm:gap-y-16'>
          {data.map((item, i) => (
            <div className='relative cursor-pointer col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 transition-all duration-300 ease-in hover:scale-105' key={i}>
              <h2 className='px-[22px] lg:px-6 py-[14px] lg:py-4 text-xl font-bold rounded-full bg-buttoncolor w-min absolute top-[10px] lg:top-2 left-[50%] transform translate-x-[-50%] translate-y-[-50%]'>{i + 1}</h2>
              <img src={bgimg} className='mx-auto w-[87%] sm:w-[90%] md:w-[80%] lg:w-[90%] xl:w-[95%] 2xl:w-[90%] h-[350px] lg:h-[380px] xl:h-[350px] ' alt="" />
              <div className='absolute px-2 sm:px-5 xl:px-7 mt-[60px] lg:mt-[70px] w-[85%] md:w-[76%] lg:w-[76%] xl:w-[99%] 2xl:w-[95%]  top-[36%] sm:top-[35%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]'>
                <img src={item?.img} className='h-24 lg:h-[120px] xl:h-28 mx-auto mb-4' alt="" />
                <h2 className='mb-5 text-lg font-bold text-center uppercase md:text-xl font-Roboto'>{item?.title}</h2>
                <p className='text-center text-gray-600 font-Roboto'>{item?.description.slice(0, 100) + "..."}</p>
              </div>
            </div>
          ))}
        </div>

      </div>

    </div>
  )
}

export default HowItWorksPart




{/*
  <div className='col-span-12 md:col-span-6 lg:col-span-3' key={i}>
              <div className='relative px-4 py-6 text-center transition-transform duration-300 transform border-2 border-dashed rounded-lg hover:shadow-2xl hover:scale-90 border-navydark'>
                <img className='h-32 mx-auto' src={item?.img} alt="icon" />
                <h1 className='mt-3 text-2xl font-medium font-Roboto'>{item?.title}</h1>
                <p className='mt-2 text-gray-600 font-Roboto'>{item?.description}</p>
                <img className='absolute top-0 left-0' src={item?.step} alt="steps" />
              </div>
            </div>
*/}