import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextInput } from 'flowbite-react'
import React from 'react'
import { useForm } from 'react-hook-form';
import { HiMail } from 'react-icons/hi'
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { setPasswordApi } from '../../apiServices/api-functions/passwordset';
import image from '../../assets/images/passwordSet/Email.png'

function PasswordSetMain() {
    const { id } = useParams()
    const PasswordSetSchema = yup.object().shape({
        password: yup.string().min(6, 'Password must be at least 6 characters').required('Please enter a password'),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Please confirm your password'),
    });

    const { control, handleSubmit, setValue, formState: { errors }, register } = useForm({
        resolver: yupResolver(PasswordSetSchema),
    });
    const onSubmit = async (data) => {
        console.log(data)
        const payload = {
            email: id,
            password: data?.password,
            confirmPassword: data?.confirmPassword
        }
        const updateApi = await setPasswordApi(payload)
        if (updateApi) {
            window.location.href = 'https://devapp.getstartbuild.com/login';
        }
    }

    return (
        <>
            <div
                className={'container relative z-10  block grid-cols-2 gap-4 xl:grid'}
            >
                <div
                    className="flex-col hidden min-h-screen xl:flex bg-blue-900"
                >
                    <div className="my-auto">
                        <img
                            alt="Midone Tailwind HTML Admin Template"
                            className="w-1/2 -mt-16 -intro-x ml-auto mr-auto"
                            src={image}
                        />
                        <div className="mt-10 text-4xl font-medium leading-tight text-white -intro-x text-center">
                            A few more clicks to <br />
                            sign in to your account.
                        </div>
                        <div className="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-slate-400 text-center">
                            Manage all your e-commerce accounts in one place
                        </div>
                    </div>
                </div>

                <div
                    className="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0"
                >
                    <div
                        className="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto"
                    >
                        <h2 className="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
                            Set a Password
                        </h2>
                        <div className="mt-2 text-black ">
                            A few more clicks to sign in to your account. Manage all your
                            e-commerce accounts in one place
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>


                            <div className="mt-4 intro-x">
                                <TextInput
                                    id="email"
                                    type="email"
                                    {...register("email")}
                                    placeholder="name@gmail.com"
                                    required
                                    defaultValue={id}
                                    readOnly
                                />
                            </div>
                            <div className='mt-2'>
                                <TextInput
                                    id="password"
                                    type="password"
                                    {...register("password")}
                                    placeholder="password"
                                    required
                                />
                                {errors.password && <p className="text-red-500">{errors.password.message}</p>}
                            </div>
                            <div className='mt-2'>
                                <TextInput
                                    id="confirmPassword"
                                    type="password"
                                    {...register("confirmPassword")}
                                    placeholder="Confirm Password"
                                    required
                                />
                                {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword.message}</p>}
                            </div>

                            <div className="mt-5 text-center intro-x xl:mt-8 xl:text-left">
                                <Button
                                    className="w-full align-top xl:w-32 xl:mr-3 bg-blue-900"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                        <div className="mt-10 text-center intro-x xl:mt-24 text-slate-600 dark:text-slate-500 xl:text-left">
                            By signing up, you agree to our{' '}
                            <a className="text-primary dark:text-slate-200" href="">
                                Terms and Conditions
                            </a>{' '}
                            &{' '}
                            <a className="text-primary dark:text-slate-200" href="">
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PasswordSetMain
