import React from 'react'
import img1 from '../../../assets/images/features/Vector.png'
import img2 from '../../../assets/images/features/Vector (1).png'
import f1 from '../../../assets/images/features/Frame 1597885079.png'
import f2 from '../../../assets/images/features/Frame 1597885079 (1).png'
import f3 from '../../../assets/images/features/Frame 1597885079 (2).png'
import f4 from '../../../assets/images/features/Frame 1597885079 (3).png'
import f5 from '../../../assets/images/features/Frame 1597885079 (4).png'
import f6 from '../../../assets/images/features/Frame 1597885079 (5).png'
import lineImg from '../../../assets/images/Home Page/OurWorks/Frame 1597881896.png'
import { FaArrowRight } from 'react-icons/fa6'

function FeaturesIndex() {

    const mockData = [
        {
            icon: f1,
            title: 'Project Tracking',
            description: 'Is a long established fact that a reader will be distracted by the readable. ',
        },
        {
            icon: f2,
            title: 'Task Management',
            description: 'Is a long established fact that a reader will be distracted by the readable. ',
        },
        {
            icon: f3,
            title: 'Report Tracking',
            description: 'Is a long established fact that a reader will be distracted by the readable. ',
        },
        {
            icon: f4,
            title: 'Customizing Plan',
            description: 'Is a long established fact that a reader will be distracted by the readable. ',
        },
        {
            icon: f5,
            title: '24/7 Site Surveillance',
            description: 'Is a long established fact that a reader will be distracted by the readable. ',
        },
        {
            icon: f6,
            title: 'Instant Quotation',
            description: 'Is a long established fact that a reader will be distracted by the readable. ',
        },
    ]
    return (
        <div className='px-[8%] py-7 lg:py-16 relative'>
            <img className='absolute w-40 left-0 top-0 opacity-45' src={img1} alt="icon" />
            <img className='absolute w-28 right-0 top-0 opacity-45' src={img2} alt="icon" />
            <div className='z-50'>
                <div className='flex flex-col items-center justify-center'>
                    <h2 className='text-[20px] sm:text-[34px] md:text-4xl font-bold font-Roboto text-navydark'>Features</h2>
                    <p className='mt-3 mb-2 text-center font-Roboto text-gray-500'>is a long established fact that a reader will be distracted by the readable. </p>
                    <img src={lineImg} className='object-contain' alt="icon" />
                </div>
                <div className='grid grid-cols-12 gap-5 mt-8'>
                    {mockData?.map((eachFeature, i) => (
                        <div key={i} className='col-span-12 md:col-span-6 lg:col-span-4'>
                            <div className='p-5 border-[1px] group border-navydark rounded-md bg-white hover:bg-navydark transition-all duration-200 ease-in-out'>
                                <img className='h-16' src={eachFeature?.icon} alt="icon" />
                                <h1 className='text-xl font-medium font-Roboto text-navydark group-hover:text-white mt-2'>{eachFeature?.title}</h1>
                                <p className='mt-2 text-gray-500 font-Roboto group-hover:text-gray-300'>{eachFeature?.description}</p>
                                <p className='mt-5 cursor-pointer flex items-center font-Roboto text-navyverylight group-hover:text-white'>Learn More <span className='ms-1 mt-1'><FaArrowRight size={15} /></span></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FeaturesIndex
