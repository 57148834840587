import { apiFunctions } from "../ApiService";
import siteUrls from "../SiteURLs";


export const getContructionType = async () => {
    return apiFunctions.get(siteUrls.requirement.construction);
};
export const getPlanType = async () => {
    return apiFunctions.get(siteUrls.requirement.plans);
};
export const getAdditional = async () => {
    return apiFunctions.get(siteUrls.requirement.additional);
};
export const getVisitor = async () => {
    return apiFunctions.get(siteUrls.requirement.visitor);
};
export const createRequirement = async (payload) => {
    return apiFunctions.post(siteUrls.requirement.requirement, payload);
};

//wizard form steps
export const requirementStep = async (id) => {
    return apiFunctions.get(`${siteUrls.requirement.step}/${id}`);
};

export const stepOneUpdate = async (payload) => {
    return apiFunctions.post(siteUrls.requirement.form.stepOne.create, payload)
}
export const stepOneUpdateAgain = async (id, payload) => {
    return apiFunctions.put(`${siteUrls.requirement.form.stepOne.get}/${id}`, payload)
}

export const stepOneGET = async () => {
    return apiFunctions.get(siteUrls.requirement.form.stepOne.get)
}
export const stepOnePincodeGET = async (query) => {
    return apiFunctions.get(`${siteUrls.requirement.form.stepOne.pincodeDrop}?${query}`);
};

export const stepTwoUpdate = async (payload) => {
    return apiFunctions.post(siteUrls.requirement.form.stepTwo.create, payload)
}
export const getRequirement = async (id) => {
    return apiFunctions.get(`${siteUrls.requirement.form.stepTwo.get}/${id}`)
}
export const getRequirementFloor = async () => {
    return apiFunctions.get(`${siteUrls.requirement.form.stepTwo.getFloor}`)
}

export const floorUpdateApi = async (id, payload) => {
    return apiFunctions.post(`${siteUrls.requirement.form.stepThree.floor}/${id}`, payload)
}
export const floorProceedApi = async (id) => {
    return apiFunctions.post(`${siteUrls.requirement.form.stepThree.update}/${id}`, {})
}
export const floorAddRemove = async (id, payload) => {
    return apiFunctions.post(`${siteUrls.requirement.form.stepThree.count}/${id}`, payload)
}
export const floorTab = async (id, lengthCount) => {
    return apiFunctions.get(`${siteUrls.requirement.form.stepThree.tab}/${id}`);
};

export const floorRoom = async (query) => {
    return apiFunctions.get(`${siteUrls.requirement.form.stepThree.room}/${query}`);
};

export const additionalFeatureUpdateApi = async (id, payload) => {
    return apiFunctions.post(`${siteUrls.requirement.form.stepFour.update}/${id}`, payload)
}


export const stepFiveUpdate = async (id, payload) => {
    return apiFunctions.post(`${siteUrls.requirement.form.stepFive.update}/${id}`, payload)
}

export const finalUpdate = async (id) => {
    return apiFunctions.post(`${siteUrls.requirement.form.stepFive.final}/${id}`, {})
}