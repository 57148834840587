import React from 'react'
import leftimg from "../../assets/images/Footer/image 1162.png";
import rightimg from "../../assets/images/Footer/image 1161.png";
import { TfiYoutube } from "react-icons/tfi";
import { MdOutlineMail, MdOutlinePhone, MdOutlineWhatsapp } from "react-icons/md";
import { FaFacebookF, FaLinkedinIn, FaLocationArrow, FaLocationCrosshairs, FaLocationDot, FaLocationPin, FaMailchimp } from "react-icons/fa6";


const Footer = () => {
  return (
    <div className="relative h-full bg-[#071A21] text-white ">
      <img src={leftimg} className='absolute top-0 left-0 ' alt="" />
     <img src={rightimg} className='absolute bottom-0 right-0 h-[300px] w-[500px]' alt="" />

      <div className="px-[8%] py-10">
        <div className="grid grid-cols-6 lg:grid-cols-9 xl:grid-cols-12 gap-y-8 gap-x-10">
          <div className="col-span-8 sm:col-span-3 lg:col-span-3 xl:col-span-3">
            <h2 className="mb-5 font-bold font-raleway">GET START BUILD</h2>
            <p className="text-[#cfd3d7] font-normal w-[280px] sm:w-full">
              Since 2023, GET START BUILD has been one of Bengaluru's top home builders. In addition to designing and planning buildings.
            </p>
            <h2 className="text-[#cfd3d7] font-Roboto font-normal mt-5">Copyright @GetStartBuild</h2>
          </div>

          <div className="col-span-8 sm:col-span-3 lg:col-span-3 xl:col-span-2">
            <h2 className="mb-5 font-bold font-raleway">QUICK LINKS</h2>

            <ul className="text-[#cfd3d7] font-Roboto font-normal">
              <li>Home</li>
              <li className="mt-3">Service</li>
              <li className="mt-3">Calculator</li>
              <li className="mt-3">About</li>
              <li className="mt-3">Contact</li>
            </ul>
          </div>

          <div className="col-span-8 sm:col-span-2 lg:col-span-3 xl:col-span-2">
            <h2 className="mb-5 font-bold font-raleway">RESOURCES</h2>

            <ul className="text-[#cfd3d7] font-Roboto font-normal">
              <li>Blogs</li>
              <li className="mt-3">Case Study</li>
              <li className="mt-3">News & Media</li>
              <li className="mt-3">About</li>
            </ul>
          </div>

          <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-5 font-raleway ">
            <h2 className="mb-5 font-bold">JOIN WITH US</h2>

            <div className="">
              <label
                htmlFor=""
                className="font-normal font-Roboto text-[#CFD3D7]"
              >
                Your Email
              </label>
              <div className="mt-2 mb-5">
                <input
              
                  type="email"
                  placeholder="Enter Your Email"
                  className="text-black border-2 border-blue-500 w-[200px] sm:w-[300px] mr-1 sm:mr-3"
                />
                <button className="py-[10px] px-5 sm:px-8 rounded-md bg-navyverylight">
                  JOIN
                </button>
              </div>
              <div className="flex">
                <FaFacebookF className="w-8 h-8 px-[2px] py-[7px] rounded-full bg-navyverylight" />
                <FaLinkedinIn className="w-8 h-8 px-[2px] py-[7px] rounded-full bg-navyverylight ml-3" />
                <TfiYoutube className="w-8 h-8 px-[2px] py-[7px] rounded-full bg-navyverylight ml-3" />
                <MdOutlineWhatsapp className="w-8 h-8 px-[1px] py-[6px] rounded-full bg-navyverylight ml-3" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-[1px] border-navylight  w-[83%] sm:w-[85%] mx-auto"></div>

      <div className="flex px-[8%] justify-between py-5 flex-wrap gap-y-5 font-Roboto text-[#cfd3d7]">
        <h2>© 2023 www.getstartbuild.com All rights reserved</h2>
        <h2 className="flex items-center justify-center "><FaLocationDot/> <span className="ml-1">BTM Layout,Bengaluru </span></h2>
        <h2 className="flex items-center justify-center "><MdOutlineMail/> <span className="ml-1">getstartbuild.com</span></h2>
        <h2 className="flex items-center justify-center "><MdOutlinePhone/> <span className="ml-1">+91 9500516721</span></h2>
      </div>

    </div>
  );
}

export default Footer