import React, { useState } from 'react'
import { Accordion } from 'flowbite-react'
import standardicon from "../../../assets/images/Home Page/ConstructionPackages/icons/sparkle (1).png"
import premiumicon from "../../../assets/images/Home Page/ConstructionPackages/icons/premium.png"
import luxuryicon from "../../../assets/images/Home Page/ConstructionPackages/icons/luxury.png"
import "./ConstructionPackagesPart.css"
import { TfiCheckBox } from 'react-icons/tfi'
import { useQuery } from '@tanstack/react-query'
import { getPlanAndPackage } from '../../../apiServices/api-functions/plansApi'
import { useNavigate } from 'react-router-dom'
import bgimage from "../../../assets/images/Home Page/ConstructionPackages/Vec.png"
import RequirementModal from '../RequirementModal/RequirementModal'
import lineImg from '../../../assets/images/Home Page/OurWorks/Frame 1597881896.png'

const ConstructionPackagesPart = () => {
   const [openRequirement, setOpenRequirement] = useState(false);

   const toggleModalRequirement = () => {
      setOpenRequirement(!openRequirement)
   }

   const getPlanData = useQuery({
      queryKey: ['getPlan'],
      queryFn: () => getPlanAndPackage(),
   });
   const navigate = useNavigate();

   return (
      <div id='package' className='relative'>
         <img src={bgimage} className='absolute top-0 left-0 -z-[50] w-[30%] md:w-[20%] opacity-45' />
         <div className='pt-4 sm:pt-8 mb-11 sm:mb-14 px-[8%] z-[1000]'>

            <div className='flex flex-col items-center justify-center'>
               <h2 className='text-[20px] sm:text-[34px] md:text-4xl font-bold font-Roboto text-navydark'>Our Construction Packages</h2>
               <p className='mt-3 font-Roboto text-gray-500'>is a long established fact that a reader will be distracted by the readable. </p>
               <img src={lineImg} className='object-contain' alt="icon" />
            </div>

            <div className='grid grid-cols-1 mt-3 md:mt-8 lg:mt-12 md:grid-cols-2 xl:grid-cols-3 gap-x-14 xl:gap-x-8 gap-y-10 z-[1000]'>

               {/* standard section */}
               <div className=''>
                  <div className='py-4 text-white rounded-t-md bg-[#28A8EA] font-Roboto'>
                     <div className='flex items-center justify-center mb-1'>
                        <img src={standardicon} className='object-cover w-8 mr-1 bg-white rounded-full px-[4px] py-[4px] ' alt="" />
                        <h2 className='mb-1 text-2xl '>STANDARD</h2>
                     </div>
                     <h2 className='text-center font-raleway'>Rs.{getPlanData?.data?.data?.result[0]?.price}/sq.ft</h2>
                     <button onClick={toggleModalRequirement} className='flex items-center justify-center px-8 py-2 mx-auto mt-4 font-semibold bg-white rounded text-navyverylight'>Get Your Estimation</button>
                  </div>
                  <div className='border-[1px] border-t-0 border-navyverylight'>
                     <Accordion alwaysOpen={false} collapseAll className='border-[1px] border-black/10 rounded-s-none rounded-e-none '>

                        {getPlanData?.data?.data?.result[0]?.gsbPackage?.map((item, index) => (

                           <Accordion.Panel key={index}>
                              <Accordion.Title className='py-3 sm:py-[14px] '>
                                 <span className='font-medium font-Roboto'>{item?.feature}</span>

                              </Accordion.Title>
                              <Accordion.Content className='px-5 py-1'>
                                 <div className='w-full font-medium font-Roboto'>
                                    {item?.listOfFeatures?.map((eachFeature, index) => {
                                       return <p className={`${item?.listOfFeatures?.length !== index + 1 ? 'border-b-[1px]' : ''} py-2 w-full flex`} key={index}>
                                          <span><TfiCheckBox className='mt-[2px] mr-1 text-blue-700' /></span>
                                          {eachFeature?.title}</p>
                                    })}
                                 </div>

                              </Accordion.Content>
                           </Accordion.Panel>
                        ))}

                     </Accordion>
                  </div>
               </div>


               {/* premium section */}
               <div className='lg:-translate-y-5'>
                  <div className='relative py-4 text-white rounded-md rounded-b-none bg-navyverylight font-Roboto '>
                     <div className='flex items-center px-4 mb-1 sm:px-0 sm:justify-center md:justify-start lg:justify-center md:px-5 lg:px-0'>
                        <img src={premiumicon} className='object-cover w-8 mr-1 bg-white rounded-full px-[3px] py-[3px]' alt="" />
                        <h2 className='mb-1 text-2xl '>PREMIUM</h2>
                     </div>
                     <h2 className='px-9 sm:px-0 sm:text-center md:text-start lg:text-center font-raleway md:px-10'>Rs.{getPlanData?.data?.data?.result[1]?.price}/sq.ft</h2>
                     <button onClick={toggleModalRequirement} className='flex items-center justify-center px-8 py-2 mx-auto mt-4 font-semibold text-black rounded bg-buttoncolor'>Get Your Estimation</button>
                     <h2 className='blink absolute top-[-.8px] sm:top-[-.5px] right-0 px-[4px] sm:px-[8px] md:px-[8px] lg:px-[6px] py-[2px] sm:py-[4px] md:py-[4px] lg:py-[3px] bg-buttoncolor text-black rounded-s-lg rounded-tl-none rounded-tr-[6.5px] text-[12px] sm:text-[13.5px] lg:text-[11px] z-20 text-end'>MOST POPULAR</h2>
                  </div>
                  <div className='border-[1px] border-t-0 border-yellowcolor'>
                     <Accordion alwaysOpen={false} collapseAll className='border-[1px] border-black/10 rounded-s-none rounded-e-none '>
                        {getPlanData?.data?.data?.result[1]?.gsbPackage?.map((item, index) => (

                           <Accordion.Panel key={index}>
                              <Accordion.Title className='py-3 sm:py-[14px] '>
                                 <span className='font-medium font-Roboto'>{item?.feature}</span>

                              </Accordion.Title>
                              <Accordion.Content className='px-5 py-1'>
                                 <div className='w-full font-medium font-Roboto'>
                                    {item?.listOfFeatures?.map((eachFeature, index) => {
                                       return <p className={`${item?.listOfFeatures?.length !== index + 1 ? 'border-b-[1px]' : ''} py-2 w-full flex`} key={index}>
                                          <span><TfiCheckBox className='mt-[2px] mr-1 text-blue-700' /></span>
                                          {eachFeature?.title}</p>
                                    })}
                                 </div>

                              </Accordion.Content>
                           </Accordion.Panel>
                        ))}
                     </Accordion>
                  </div>
               </div>


               {/* Luxury section */}
               <div className=''>
                  <div className='py-4 text-white rounded-md rounded-b-none bg-[#5059C9] font-Roboto'>
                     <div className='flex items-center justify-center mb-1'>
                        <img src={luxuryicon} className='object-cover w-8 mr-1 bg-white rounded-full px-[3px] py-[3px]' alt="" />
                        <h2 className='mb-1 text-2xl '>LUXURY</h2>
                     </div>
                     <h2 className='text-center font-raleway'>Rs.{getPlanData?.data?.data?.result[2]?.price}/sq.ft</h2>
                     <button onClick={toggleModalRequirement} className='flex items-center justify-center px-8 py-2 mx-auto mt-4 font-semibold bg-white rounded text-navyverylight'>Get Your Estimation</button>
                  </div>
                  <div className='border-[1px] border-t-0 border-navyverylight'>
                     <Accordion alwaysOpen={false} collapseAll className='border-[1px] border-black/10 rounded-s-none rounded-e-none '>

                        {getPlanData?.data?.data?.result[2]?.gsbPackage?.map((item, index) => (
                           <Accordion.Panel key={index}>
                              <Accordion.Title className='py-3 sm:py-[14px] '>
                                 <span className='font-medium font-Roboto'>{item?.feature}</span>

                              </Accordion.Title>
                              <Accordion.Content className='px-5 py-2'>
                                 <div className='w-full font-medium font-Roboto'>
                                    {item?.listOfFeatures?.map((eachFeature, index) => {
                                       return <p className={`${item?.listOfFeatures?.length !== index + 1 ? 'border-b-[1px]' : ''} py-2 w-full flex`} key={index}>
                                          <span><TfiCheckBox className='mt-[2px] mr-1 text-blue-700' /></span>
                                          {eachFeature?.title}</p>
                                    })}
                                 </div>

                              </Accordion.Content>
                           </Accordion.Panel>
                        ))}
                     </Accordion>
                  </div>
               </div>

            </div>
         </div>
         <RequirementModal isModal={openRequirement} toggle={toggleModalRequirement} count={'1'} />
      </div>
   )
}

export default ConstructionPackagesPart