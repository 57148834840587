import { objectToQueryString } from "../../utils/helper";
import { apiFunctions } from "../ApiService";
import siteUrls from "../SiteURLs";


export const getCountry = async () => {
    return apiFunctions.get(siteUrls.location.country);
};
export const getState = async (country) => {
    return apiFunctions.get(
        `${siteUrls.location.state}?${objectToQueryString({ country })}`
    );
};
export const getCity = async (state) => {
    return apiFunctions.get(
        `${siteUrls.location.city}?${objectToQueryString({ state })}`
    );
};
