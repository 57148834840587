import React, { useState } from 'react'
import * as yup from 'yup';
import {
    Controller,
    SubmitHandler,
    useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaArrowLeft, FaArrowRight, FaCircleExclamation, FaMinus, FaPlus } from 'react-icons/fa6';
import FloorUpdate from './FloorUpdate';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { floorProceedApi, floorTab } from '../../../../../apiServices/api-functions/requirementApi';
import { FaCheckCircle } from 'react-icons/fa';
import { Spinner, Tooltip } from 'flowbite-react';
import toast from 'react-hot-toast';

function StepThreeEstimation({ nextPage, prevPage }) {
    const [currentTab, setCurrentTab] = useState(0);
    const [tabCount, setTabCount] = useState(0);
    const reqId = localStorage.getItem('reqId')
    const queryClient = useQueryClient();

    const handleNextTab = () => {
        setCurrentTab((prevPage) => prevPage + 1);
    };

    const handlePrevTab = () => {
        setCurrentTab((prevPage) => prevPage - 1);
    };

    const getTabData = useQuery({
        queryKey: ['getFloorTab'],
        queryFn: () => floorTab(reqId)
    })

    const proceedFunc = async () => {
        const updateApi = await floorProceedApi(reqId)
        if (updateApi) {
            toast.success('Floor Details Updated')
            localStorage.setItem('reqId', updateApi?.data?.result?._id)
            queryClient.invalidateQueries([
                'stepRequirement',
            ]);
            nextPage()
        }
    }

    const { isLoading, isError } = getTabData;
    if (isLoading) {
        return (
            <div className="h-full flex items-center justify-center">
                <Spinner color="info" aria-label="Info spinner example" />
            </div>
        );
    }

    if (isError) {
        return <div>Error: Unable to fetch data</div>;
    }

    const renderPageContent = () => {
        return (
            <div>
                {getTabData?.data?.data?.result?.details?.map((item, index) => (
                    <div key={index}>
                        {currentTab === index && (
                            <FloorUpdate
                                floor={index}
                                dataTab={item}
                                length={getTabData?.data?.data?.result?.details.length}
                                addTab={handleNextTab}
                                removeTab={handlePrevTab}
                                onNextTab={handleNextTab}
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className='flex flex-col justify-between h-full'>
            <div>
                <div>
                    <h1 className='font-medium text-xl text-gray-800 font-Roboto'>Floor Details</h1>
                    <p className='text-xs text-gray-400 mt-1 mb-2 font-Roboto'>Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's.</p>
                </div>
                <div className='flex flex-wrap gap-4 gap-y-1'>
                    {getTabData?.data?.data?.result?.details?.map((item, i) => (
                        <h1 onClick={() => setCurrentTab(i)} className={`cursor-pointer text-base font-medium flex items-center${currentTab === i ? ' border-b-2 border-gray-600' : ''}`}>{item?.name} {item?.completed ? (<span className='text-green-500 ms-1'><Tooltip content={`Submitted: ${item?.name}`}><FaCheckCircle /></Tooltip></span>) : (<span className='text-yellow-300 ms-1'><Tooltip content={`Pending: Update ${item?.name}`}><FaCircleExclamation /></Tooltip></span>)} </h1>
                    ))}
                </div>
                <div>
                    {renderPageContent()}
                </div>
            </div>
            <div className='flex justify-end'>
                {getTabData?.data?.data?.result?.tabComplete && (
                    <div className="flex">
                        <div className='flex ml-auto'>
                            <div>
                                <button className='prev-btn' onClick={prevPage} > <FaArrowLeft className="cursor-pointer me-1" /> Previous</button>
                            </div>
                        </div>
                        <div className='flex'>
                            <div>
                                <button className='next-btn' onClick={proceedFunc}>Proceed to Next <span className='ms-1 pt-[3px]'><FaArrowRight /></span></button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default StepThreeEstimation
